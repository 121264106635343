import store from '../../store'
import { PERM_BIZ, PERM_ADMIN, PERM_AGENT } from '../../constants'

export function isAdmin (to, from, next) {
  const user = store.getters.getUser || false
  if (!user || user.level < PERM_ADMIN) {
    console.log('No permisssions')
    next({
      name: 'home'
    })
    return false
  }
}
export function isBiz (to, from, next) {
  const user = store.getters.getUser || false
  if (!user || user.level < PERM_BIZ) {
    console.log('No permisssions')
    next({
      name: 'home'
    })
    return false
  }
}
export function isAgent (to, from, next) {
  const user = store.getters.getUser || false
  if (!user || user.level < PERM_AGENT) {
    console.log('No permisssions')
    next({
      name: 'home'
    })
    return false
  }
}

export default { isAdmin, isBiz, isAgent }
