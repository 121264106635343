<template>
  <div class="home">
    <!-- Search -->
    <div
      class="home-search-container"
      :class="{ hidden: !getVisibleHome }"
      :style="getRandoomBackGroudImg"
      ref="searchContainer"
    >
      <!-- <div class="home-search-container-license">
        <div @click="$router.push('license')" class="home-search-container-license-button">
          <span>{{ $t('general.license') }}</span>
        </div>
      </div> -->
      <div class="home-search-container-center-filter">
        <div class="home-search-container-center-filter-content">
          <div class="home-search-container-title">
            <span>{{ $t('public.whereToEat') }}</span>
          </div>
          <div class="home-search-container-center-filter-inputs">
            <input-search
              @selected="visibilityResults(true)"
              v-observe-visibility="visibilitySearchNavBarChanged"
            />
          </div>
        </div>
      </div>

      <!-- Inspirarte -->
      <div class="home-search-container-inspiration">
        <div
          class="home-search-container-inspiration-link"
          @click="visibilityResults(true)"
        >
          <span class="home-search-container-inspiration-link-text-large">{{
            $t('public.letsGetInspired')
          }}</span>
          <span class="home-search-container-inspiration-link-text-small">{{
            $t('public.getInspired')
          }}</span>
          <div>
            <i
              class="el-icon-arrow-down"
              style="color: #ffad5c; font-size: 60px"
            ></i>
          </div>
        </div>
      </div>
      <div class="home-search-container-footer">
        <span
          class="home-search-container-footer-link"
          @click.stop="$router.push('terms')"
          >{{ $t('general.legalTerms') }}</span
        >
        <span
          class="home-search-container-footer-link"
          @click.stop="$router.push('privacy')"
          >{{ $t('general.privacyStatement') }}</span
        >
        <span
          class="home-search-container-footer-link"
          @click.stop="$router.push('license')"
          >{{ $t('general.license') }}</span
        >
      </div>
    </div>

    <!-- Results -->
    <div class="home-result-container" ref="resultsContainer">
      <!-- Cards y Filters -->
      <div
        class="home-result-container-cards"
        :class="{ 'with-map': map.showMap && map.sideMap }"
      >
        <!-- Filters  -->
        <div
          class="home-result-container-cards-filters"
          v-show="checkActiveMainFilters"
        >
          <div class="home-result-container-cards-filters-icons text-right">
            <template v-if="isMobil">
              <i
                v-if="getFilters.useDestination || getFilters.useLocation"
                class="el-icon-map-location map-toggle-button"
                :class="{ active: checkCanUseMap && map.showMapMobile }"
                @click="map.showMapMobile = !map.showMapMobile"
              ></i>
            </template>
            <template v-else>
              <i
                class="el-icon-menu map-toggle-button"
                style="margin-right: 0.5em"
                :class="{ active: !checkCanUseMap || !map.showMap }"
                @click="map.showMap = false"
                v-if="map.sideMap"
              ></i>
              <i
                v-if="getFilters.useDestination || getFilters.useLocation"
                class="el-icon-map-location map-toggle-button"
                :class="{ active: checkCanUseMap && map.showMap }"
                @click="map.showMap = true"
              ></i>
            </template>
          </div>

          <div class="home-result-container-cards-filters-btn">
            <el-button
              size="mini"
              class="btn-filter"
              @click="dialogShowCuisineFilter = true"
              :disabled="!cards.length"
            >
              {{ $t('general.cuisine') }}
            </el-button>
            <el-dropdown @command="handleFilterPrice">
              <el-button
                size="mini"
                class="btn-filter"
                :disabled="!cards.length"
              >
                {{ $t('general.price')
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="1">{{
                  $t('general.selectPriceEconomic')
                }}</el-dropdown-item>
                <el-dropdown-item command="2">{{
                  $t('general.selectPriceModerate')
                }}</el-dropdown-item>
                <el-dropdown-item command="3">{{
                  $t('general.selectPriceExpensive')
                }}</el-dropdown-item>
                <el-dropdown-item command="4">{{
                  $t('general.selectPriceLuxurious')
                }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button
              size="mini"
              class="btn-filter"
              :disabled="!cards.length"
              :class="{ active: getFilters.takeAway }"
              @click="handleFilterTakeAway"
            >
              {{ $t('general.takeAway') }}
            </el-button>
            <!-- <el-button
              size="mini"
              class="btn-filter"
              :disabled="!cards.length"
              :class="{ active: getFilters.isOpen }"
              @click="handleFilterIsOpen"
            >
              {{ $t('public.opened') }}
            </el-button> -->
          </div>

          <div class="home-result-container-cards-filters-btn-clear">
            <el-row>
              <el-button
                type="danger"
                plain
                size="mini"
                icon="el-icon-food"
                v-for="(cuisine, index) in getFilters.cuisine"
                :key="`btn-filter-cuisine-${index}`"
                @click="cleanFilters('cuisine', cuisine)"
                class="button-remove-filter"
                >{{ cuisine.name }}<span class="button-icon-close"
              /></el-button>
              <el-button
                type="danger"
                plain
                size="mini"
                v-if="getFilters.priceRange"
                @click="cleanFilters('price-range')"
                class="button-remove-filter"
              >
                <span v-if="getFilters.priceRange === '1'">{{
                  $t('general.selectPriceEconomic')
                }}</span>
                <span v-if="getFilters.priceRange === '2'">{{
                  $t('general.selectPriceModerate')
                }}</span>
                <span v-if="getFilters.priceRange === '3'">{{
                  $t('general.selectPriceExpensive')
                }}</span>
                <span v-if="getFilters.priceRange === '4'">{{
                  $t('general.selectPriceLuxurious')
                }}</span>
                <span class="button-icon-close" />
              </el-button>
              <el-button
                type="danger"
                plain
                size="mini"
                icon="el-icon-shopping-cart-2"
                v-if="getFilters.takeAway"
                @click="cleanFilters('takeaway')"
                class="button-remove-filter"
                >{{ $t('general.takeAway') }}<span class="button-icon-close"
              /></el-button>
              <!-- <el-button
                type="danger"
                plain
                size="mini"
                icon="el-icon-time"
                v-if="getFilters.isOpen"
                @click="cleanFilters('is-open')"
                class="button-remove-filter"
                >{{ $t('public.opened') }}<span class="button-icon-close"
              /></el-button> -->
              <el-button
                type="danger"
                plain
                size="mini"
                icon="el-icon-notebook-2"
                v-if="getFilters.text"
                @click="cleanFilters('text')"
                class="button-remove-filter"
                >{{ getFilters.text }}<span class="button-icon-close"
              /></el-button>
            </el-row>
          </div>
        </div>

        <!-- Results  -->
        <div v-loading="loadingCards" style="min-height: 100px">
          <!-- Alert No result  -->
          <el-alert
            :title="$t('public.noResultsSearch')"
            type="info"
            show-icon
            :closable="false"
            v-if="
              !cards.length &&
              !!cardsInspiration.length &&
              this.checkActiveMainFilters
            "
          >
          </el-alert>

          <!-- Cards -->
          <div
            v-show="cards.length > 0"
            class="home-result-container-cards-items"
            :class="{
              horizontal:
                checkCanUseMap && map.showMap && map.sideMap && !isMobil,
              vertical: !checkCanUseMap || !map.showMap || isMobil
            }"
            v-observe-visibility="checkCanUseMap ? visibilityResults : false"
          >
            <Card
              v-for="(card, index) in cards"
              :key="`result-card-${index}`"
              :card="card"
              :horizontal="
                checkCanUseMap && map.showMap && map.sideMap && !isMobil
                  ? true
                  : false
              "
              :cardHoverPopUp="cardHoverPopUp"
              :popUp="false"
              :class="{
                'hover-select': card.r_id === cardHoverPopUp
              }"
              @mouseover.native="handleCardHoverIn(card)"
              @mouseleave.native="handleCardHoverOut()"
              @click="handleCardClick($event)"
            ></Card>
            <div
              v-for="n in 10"
              class="r-card r-card-dummy"
              :key="'dummy-' + n"
            />
          </div>

          <!-- Cards  Inspiration -->
          <div v-if="!cards.length && !!cardsInspiration.length">
            <el-divider content-position="left" v-if="checkActiveMainFilters">{{
              $t('public.recommended')
            }}</el-divider>
            <!-- Cards -->
            <div
              class="home-result-container-cards-items"
              :class="{
                horizontal:
                  checkCanUseMap && map.showMap && map.sideMap && !isMobil,
                vertical: !checkCanUseMap || !map.showMap || isMobil
              }"
            >
              <Card
                v-for="card in cardsInspiration"
                :key="card.id"
                :card="card"
                :horizontal="
                  checkCanUseMap && map.showMap && map.sideMap && !isMobil
                    ? true
                    : false
                "
                :popUp="false"
                @click="handleCardClick($event)"
              ></Card>
              <div
                v-for="n in 10"
                class="r-card r-card-dummy"
                :key="'dummy-' + n"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        class="home-result-container-map home-result-mobil-menu"
        :class="{ active: map.showMap, 'active-mobile': map.showMapMobile }"
        v-if="checkCanUseMap"
      >
        <div
          class="home-result-mobil-menu-mobile-part"
          @click="map.showMapMobile = !map.showMapMobile"
        >
          <i class="home-result-mobil-menu-button-icon el-icon-d-arrow-right" />
        </div>
        <Map
          :visible="map.showMap || map.showMapMobile"
          :cards="cards"
          :cardHover="cardHover"
          @cardClick="handleCardClick($event)"
          @cardHover="handleCardPopUpHover($event)"
          @dragend="handleDragend($event)"
        />
      </div>
    </div>

    <!-- Dialog Filter Cuisine -->
    <el-dialog
      custom-class="dialog-cuisine-filter"
      :visible.sync="dialogShowCuisineFilter"
      :append-to-body="true"
    >
      <el-input
        :placeholder="$t('public.search')"
        v-model="dialogInputCuisineFilter"
        class="mb-2 mt-2"
      ></el-input>
      <div>
        <el-row :gutter="10">
          <el-col
            :xs="24"
            :sm="24"
            :md="8"
            :lg="8"
            :xl="8"
            v-for="(cuisine, index) in getArrCuisinesForFilters"
            :key="index"
          >
            <div
              class="basic-shadow-box mb-1 text-center p-1"
              @click="handleFilterCuisineFilter(cuisine)"
            >
              {{ cuisine.name }}
            </div>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import InputSearch from '@/components/InputSearch.vue'
import Card from '@/components/Card.vue'
import Map from '@/components/Map.vue'
import Vue from 'vue'
import { ObserveVisibility } from 'vue-observe-visibility'
Vue.directive('observe-visibility', ObserveVisibility)

export default {
  components: {
    InputSearch,
    Card,
    Map
  },
  data () {
    return {
      backGroundImages: [
        {
          start: 5,
          finish: 11,
          imgs: [
            "url('/assets/img/home/home1.jpg')",
            "url('/assets/img/home/home2.jpg')"
          ]
        },
        {
          start: 11,
          finish: 15,
          imgs: [
            "url('/assets/img/home/home3.jpg')",
            "url('/assets/img/home/home4.jpg')"
          ]
        },
        {
          start: 15,
          finish: 19,
          imgs: [
            "url('/assets/img/home/home5.jpg')",
            "url('/assets/img/home/home6.jpg')"
          ]
        },
        {
          start: 19,
          finish: 5,
          imgs: [
            "url('/assets/img/home/home7.jpg')",
            "url('/assets/img/home/home5.jpg')"
          ]
        }
      ],
      arrCuisines: [],
      cards: [],
      cardsInspiration: [],
      loadingCards: true,
      cardHover: null,
      cardHoverPopUp: null,
      showFilters: false,
      dialogShowCuisineFilter: false,
      dialogInputCuisineFilter: '',
      map: {
        showMap: true,
        showMapMobile: false,
        sideMap: true
      }
    }
  },
  computed: {
    ...mapGetters([
      'getMediaURI',
      'getWindowType',
      'isMobil',
      'getGeoLocationStatus',
      'getVisibleHome',
      'getFilters',
      'getFilterStatus',
      'getLocation',
      'getUseLocation'
    ]),
    getRandoomBackGroudImg () {
      let hour = new Date().getHours()
      if (hour > 24 || hour < 0) hour = 20
      const imgs = this.backGroundImages.find((element) => {
        const start = element.start
        const finish = element.finish
        if (start < finish) {
          if (start <= hour && hour < finish) return element
        } else {
          if (hour >= 12 && hour <= 24 && start <= hour) return element
          else if (hour >= 0 && hour < 12 && hour < finish) return element
        }
      })

      const random = Math.floor(Math.random() * imgs.imgs.length)
      return {
        backgroundImage: imgs.imgs[random]
      }
    },
    checkActiveMainFilters () {
      if (this.getFilters.useLocation) return true
      if (this.getFilters.useDestination) return true
      if (this.getFilters.mapBounds) return true
      if (this.getFilters.text && this.getFilters.text !== '') return true
      return false
    },
    checkCanUseMap () {
      if (
        (this.getGeoLocationStatus && this.getFilters.useLocation) ||
        this.getFilters.useDestination
      ) {
        return true
      }
      return false
    },
    getArrCuisinesForFilters () {
      return this.arrCuisines.filter((element) => {
        if (
          !this.getFilters.cuisine.find(
            (element2) => element2.id === element.id
          )
        ) {
          if (
            element.name
              .toLowerCase()
              .indexOf(this.dialogInputCuisineFilter.toLowerCase()) !== -1 ||
            this.dialogInputCuisineFilter === ''
          ) {
            return element
          }
        }
      })
    }
  },
  watch: {
    getFilterStatus: function (value) {
      if (value) {
        this.$store.commit('setFilterStatus', false)
        this.getCards()
      }
    }
  },

  async created () {
    this.$store.commit('setFilterStatus', false)
    this.getCards()
    this.getCuisines()
    this.sendNewVisit()
  },
  methods: {
    ...mapActions(['getPosition']),
    async getCards () {
      this.loadingCards = true
      try {
        if (this.getUseLocation && !this.getLocation) {
          await this.getPosition()
        }

        if (this.checkActiveMainFilters) {
          this.cards = await this.getCardsWithFilters()
        } else {
          this.cards = []
        }
        if (
          !this.cardsInspiration.length &&
          (!this.checkActiveMainFilters || !this.cards.length)
        ) {
          this.cardsInspiration = await this.getCardsInspire()
        }
        this.loadingCards = false
      } catch (error) {
        this.loadingCards = false
        console.log(error.body ? error.body : 'Cards load error')
      }
    },
    async getCardsWithFilters () {
      try {
        const queryResponse = await this.$http.post('restaurant/items/', {
          filters: this.getFilters,
          time_now: this.moment().format('YYYY-MM-DD hh:mm:ss')
        })
        if (!queryResponse.body.error) {
          // console.log(queryResponse)
          return queryResponse.body
        } else {
          console.log('err', queryResponse.body.error)
          return []
        }
      } catch (error) {
        console.log(error.body ? error.body : 'restaurant load error')
        return []
      }
    },
    async getCardsInspire () {
      try {
        const queryResponse = await this.$http.post(
          'restaurant/items_inspire/',
          {
            getGeoLocationStatus: this.getGeoLocationStatus,
            time_now: this.moment().format('YYYY-MM-DD hh:mm:ss'),
            filters: this.getFilters
          }
        )
        if (!queryResponse.body.error) {
          return queryResponse.body
        } else {
          console.log('err', queryResponse.body.error)
          return []
        }
      } catch (error) {
        console.log(error.body ? error.body : 'restaurant load error')
        return []
      }
    },
    cleanFilters (value, aux = false) {
      switch (value) {
        case 'mapa':
          this.$store.commit('setMapBounds', null)
          break

        case 'location':
          this.$store.commit('setUseLocation', false)
          this.$store.commit('setLimitToLocation', false)
          // this.map.showMap = false
          break

        case 'destination':
          this.$store.commit('setUseDestination', false)
          this.$store.commit('setDestinationBounds', null)
          this.$store.commit('setDestinationLocation', null)
          // this.map.showMap = false
          break

        case 'text':
          this.$store.commit('setFilterText', '')
          break

        case 'cuisine': {
          this.$store.commit('removeFilterCuisine', aux)
          break
        }

        case 'takeaway': {
          this.$store.commit('setFilterTakeAway', false)
          break
        }

        case 'is-open': {
          this.$store.commit('setFilterIsOpen', false)
          break
        }

        case 'price-range': {
          this.$store.commit('setFilterPriceRange', null)
          break
        }

        case 'all': {
          this.$store.commit('removeFilterCuisine', false)
          this.$store.commit('setFilterTakeAway', false)
          this.$store.commit('setFilterIsOpen', false)
          this.$store.commit('setFilterPriceRange', null)
          break
        }

        default:
          break
      }
      this.getCards()
    },
    selectCurrentLocation () {
      if (this.getFilters.location) {
        this.$store.commit('setLimitToLocation', true)
        this.$store.commit('setUseDestination', false)
        this.$store.commit('setDestinationBounds', {})
        this.$store.commit('setDestinationLocation', {})
        this.$store.commit('setMapBounds', null)
        this.$store.commit('setUseLocation', true)
        this.$store.commit('setDestinationID', 0)
      }
    },
    handleDragend (e) {
      this.getCards()
    },
    handleCardClick (id) {
      this.$router
        .push({
          name: 'restaurant',
          params: { id: `${id}` }
        })
        .catch(() => {})
    },
    handleCardPopUpHover (id) {
      this.cardHoverPopUp = id
      this.$nextTick(function () {
        if (this.$refs[`result-r-card-${id}`]) {
          this.$refs[`result-r-card-${id}`].focus()
        }
      })
    },
    handleCardHoverIn (card) {
      if (
        this.checkCanUseMap &&
        ((!this.isMobil && this.map.showMap) ||
          (this.isMobil && this.map.showMapMobile))
      ) {
        if (!this.cardHover) {
          this.cardHover = card
        }
      }
    },
    handleCardHoverOut () {
      if (this.cardHover) {
        this.cardHover = false
      }
    },
    visibilitySearchNavBarChanged (isVisible, entry) {
      this.$store.commit('setShowSearchNavBar', !isVisible)
    },
    visibilityResults (isVisible, entry) {
      if (this.getVisibleHome && isVisible) {
        this.$store.commit('setVisibleHome', false)
      }
    },
    getCuisines () {
      this.$http.get('info/cuisine/').then(
        (response) => {
          if (!response.body.error) {
            if (response.body.length) {
              const cuisines = response.body
              for (const cuisine of cuisines) {
                this.arrCuisines.push({
                  id: cuisine.rc_id,
                  name: cuisine.rc_name_fb
                })
              }
            }
          } else {
            console.log('err', response.body.error)
          }
        },
        (error) => {
          console.log(error.body ? error.body : 'cuisines load error')
        }
      )
    },
    handleFilterCuisineFilter (cuisine) {
      const cuisineSelect = this.getFilters.cuisine.find(
        (element) => element.id === cuisine.id
      )

      this.dialogShowCuisineFilter = false
      this.dialogInputCuisineFilter = ''

      if (cuisineSelect) this.$store.commit('removeFilterCuisine', cuisine)
      else this.$store.commit('setFilterCuisine', cuisine)

      this.getCards()
    },
    handleFilterTakeAway () {
      this.$store.commit('setFilterTakeAway', !this.getFilters.takeAway)
      this.getCards()
    },
    handleFilterIsOpen () {
      this.$store.commit('setFilterIsOpen', !this.getFilters.isOpen)
      this.getCards()
    },
    handleFilterPrice (priceRange) {
      this.$store.commit('setFilterPriceRange', priceRange)
      this.getCards()
    },
    sendNewVisit () {
      this.$http
        .post('stats/new_visit', {
          type: 'home',
          device: this.$device.machine.type
        })
        .then(
          (response) => {},
          (error) => {
            console.log(error.body ? error.body : 'stat error')
          }
        )
    }
    // changeTypeOfMap () {
    //   if (this.getWindowType !== 'xs' && this.getWindowType !== 'sm') {
    //     if (!this.map.sideMap) this.map.showMap = false
    //     this.map.drawerMap = false
    //     this.map.sideMap = true
    //   } else {
    //     if (!this.map.drawerMap) this.map.showMap = false
    //     this.map.drawerMap = true
    //     this.map.sideMap = false
    //   }
    // }
  }
}
</script>

<style lang="less">
@import url('../assets/css/variables.less');
@min_width_cards: 420px;

.home {
  scroll-behavior: smooth;
  position: fixed;
  top: @header_height;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
.home-search-container {
  position: relative;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  background: @color-secondary;
  background-size: cover;
  background-position: center center;
  opacity: 1;
  transition: height @transition-time-curve,
    opacity @transition-time ease-in-out;
  &.hidden {
    height: 0;
    opacity: 0;
  }

  &-footer {
    position: absolute;
    z-index: 10;
    line-height: 20px;
    font-size: 12px;
    bottom: 0;
    padding: 0 5px;
    border-radius: 0 4px 0 0;
    background: fadeout(@color-secondary, 50%);
    &-link {
      color: white;
      text-decoration: none;
      display: inline-block;
      margin: 0 5px;
      cursor: pointer;
      @media (max-width: @break-mobile) {
        display: block;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &-license {
    position: absolute;
    left: 0;
    right: 0;
    top: e('calc(30vh - 180px)');
    // top: 20px;
    text-align: center;
    &-button {
      display: inline-block;
      background: fadeout(darken(@color-primary, 35%), 55%);
      padding: 15px 20px;
      border-radius: 5px;
      text-align: center;
      backdrop-filter: blur(12px);
      border: 2px solid fadeout(@color-primary, 60%);
      color: white;
      transition: all 250ms ease-in-out;
      cursor: pointer;
      &:hover {
        background: fadeout(@color-primary, 20%);
      }
    }
  }
  &-center {
    &-filter {
      position: absolute;
      left: 0;
      right: 0;
      top: e('calc(30vh - 86px)');
      // top: e('calc(40% - 86px)');
      &-content {
        background: rgba(0, 0, 0, 0.25);
        padding: 30px 30px 40px 30px;
        text-align: center;
        backdrop-filter: blur(12px);
      }
      &-inputs {
        max-width: 800px;
        margin: 0 auto;
        .r-input-search {
          max-width: unset;
        }
      }
    }
  }
  &-title {
    font-size: 2.5em;
    color: antiquewhite;
    margin-bottom: 15px;
  }
  &-inspiration {
    text-transform: uppercase;
    font-size: 1.5em;
    color: antiquewhite;
    text-align: center;
    margin: 0;
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translate(-50%, 0);
    &-link {
      cursor: pointer;
      & > span {
        text-shadow: 1px 0 3px rgba(0, 0, 0, 0.25),
          -1px 0 3px rgba(0, 0, 0, 0.25), 0 1px 3px rgba(0, 0, 0, 0.25),
          0 -1px 3px rgba(0, 0, 0, 0.25);
      }
      &-text-small {
        display: none;
      }
      @media (max-width: @break-mobile) {
        &-text-large {
          display: none;
        }
        &-text-small {
          display: inline;
        }
      }
      & > div > i {
        animation: arrow_bounce 1s cubic-bezier(0.32, 0.04, 0.94, 0.42) 0s 10
          alternate-reverse forwards;
      }
    }
  }
}
@keyframes arrow_bounce {
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(10px);
  }
}
.home-result-container {
  flex-direction: row;
  display: flex;
  height: 100%;
  .el-loading-mask {
    z-index: 5;
  }
  &-cards {
    flex-grow: 1;
    max-width: 100%;
    height: auto;
    &-filters {
      padding: 1em 1em 0 1em;
      &-btn {
        display: inline;
      }
      &-icons {
        float: right;
      }
    }
    &.with-map {
      width: @min_width_cards !important;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
    }
    &-items {
      display: flex;
      scroll-behavior: smooth;
      flex: auto;
      overflow-x: hidden;
      overflow-y: auto;
      flex-wrap: wrap;
      padding: 0.5em;
      box-sizing: border-box;
      &.horizontal {
        width: 100%;
      }
      &.vertical {
        flex-direction: row;
      }
      &.mobil {
        flex-direction: column;
      }
    }
    .el-divider.el-divider--horizontal {
      width: 90%;
      margin-left: 1em;
      max-width: 500px;
    }
  }
  &-map {
    display: none;
    width: e('calc(100% - @{min_width_cards})');
    height: 100%;
    overflow: hidden;
    &.active {
      display: flex;
    }
    @media (max-width: @break-tablet) {
      display: flex;
      position: fixed;
      top: @header_height;
      left: 0;
      bottom: 0;
      width: 100vw;
    }
  }
  .el-alert {
    width: 90%;
    margin-left: 1em;
    max-width: 500px;
    .el-alert__title {
      margin-left: 1em;
      font-size: 16px;
    }
  }
}

.map-toggle-button {
  cursor: pointer;
  font-size: 25px;
  line-height: 28px !important;
  &.active {
    color: #ffad5c;
  }
}

.el-button.btn-filter {
  margin: 0px !important;
  margin-bottom: 1em !important;
  margin-right: 1em !important;
}

.el-button.btn-filter:focus {
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
}

.el-button.btn-filter.active {
  color: #f93 !important;
  border-color: #ffe0c2 !important;
  background-color: #fff5eb !important;
}

.button-remove-filter {
  margin-right: 10px !important;
  margin-bottom: 5px !important;
  margin-left: 0px !important;
}

.home-result-mobil {
  &-menu-mobile-part {
    display: none;
  }
  @media (max-width: @break-tablet) {
    &-menu {
      width: e('calc(100vw - 15px)');
      background-color: @color-secondary;
      position: fixed;
      top: 0;
      bottom: 0;
      // left: 0;
      padding-top: @header_height;
      overflow-x: hidden;
      overflow-y: auto;
      z-index: 5;
      left: 100%;
      z-index: 5;
      transition: left 225ms cubic-bezier(0, 0, 0.2, 1);
      &-button-icon {
        font-size: 24px;
      }
      .el-menu {
        border: none;
        border-right: none;
      }
      &-mobile-part {
        background-color: @color-secondary;
        border: 1px solid darken(@color-secondary, 5%);
        border-left: none;
        border-radius: 0 3px 3px 0px;
        color: @color-primary;
        box-shadow: 3px 0 3px rgba(0, 0, 0, 0.25);
        position: absolute;
        display: block;
        margin: 20px 0 0 0;
        padding: 10px 1px;
        z-index: 1;
      }
      &-overlay {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: black;
        opacity: 0.5;
        z-index: 5;
        transition: all 0.2s;
        animation: opacity_0_50 0.5s;
      }
      &.active-mobile {
        left: 15px;
        box-shadow: -15px 0 0 rgba(0, 0, 0, 0);
        animation: shadow_overlay 0.2s ease-in-out 0.2s forwards;
      }
    }
  }
}
@keyframes shadow_overlay {
  0% {
    box-shadow: -15px 0 0 rgba(0, 0, 0, 0);
  }

  100% {
    box-shadow: -15px 0 0 rgba(0, 0, 0, 0.65);
  }
}

.hover-select {
  box-shadow: 0px 0px 5px 2px #ff9933;
}

.button-icon-close {
  display: inline-block;
  position: relative;
  width: 16px;
  margin: 0 -10px 0 7px;
  vertical-align: middle;
  transition: 0.05s;
  &:before {
    position: absolute;
    top: -15px;
    left: 0;
    width: 22px;
    height: 28px;
    border-radius: 0 3px 3px 0;
    content: '';
    background: #fbc4c4;
  }
  &::after {
    position: absolute;
    content: 'X';
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: white;
    top: -7px;
    left: 7px;
  }
}
button:hover .button-icon-close {
  &:before {
    width: 0;
    height: 0;
  }
}

.dialog {
  &-cuisine-filter {
    .basic-shadow-box {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      cursor: pointer;
    }
    .basic-shadow-box:hover {
      background-color: #ff993381;
      border-color: #86511c;
    }
  }
}
</style>
