import Vue from 'vue'
import Vuex from 'vuex'
// import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    promoAgentCode: localStorage.getItem('promoAgentCode'),
    loginDialog: {
      visible: false,
      type: 0 // 0 login, 1 register, 2 pass recover
    },
    screenTypes: {
      xs: 500,
      sm: 768,
      md: 992,
      lg: 1200,
      xl: 1920
    },
    device: {
      windowWidth: null
    },
    currentJWT: localStorage.getItem('tokenJWT'),
    cookieNotified: localStorage.getItem('cookieNotified'),
    cookieVisible: false,
    currentLanguage: localStorage.getItem('language'),
    user: {
      id: 0,
      level: 0,
      firstName: '',
      lastName: '',
      image: ''
    },
    agent: {
      ra_id: 0,
      ra_user: 0,
      ra_code: '',
      ra_destination: 0,
      ra_dest_desc: '',
      ra_created: '',
      ra_active: 0,
      ra_parent: 0
    },
    showPremium: false,
    visibleHome: true,
    geoLocationStatus: false,
    filters: {
      status: false,
      showSearchNavBar: false,
      q: '',
      takeAway: false,
      priceRange: false,
      text: '',
      isOpen: false,
      useLocation: false,
      limitToLocation: true,
      location: null,
      defaultLocationDistance: 1000,
      locationDistance: 1000,
      mapBounds: null,
      useDestination: false,
      destinationID: 0,
      destinationText: '',
      destinationBounds: {},
      destinationLocation: {},
      cuisine: []
    },
    cuisine: [],
    allergens: [],
    restaurants: {
      r_id: -1,
      r_name_fb: '',
      r_name_lh: null,
      r_text_fb: '',
      r_text_lh: null,
      r_visible: 1,
      r_takeaway: 1,
      r_city: '',
      r_address: '',
      r_zip: '',
      r_destination: 0,
      r_location: '',
      r_phone: '',
      r_whatsapp: '',
      r_email: '',
      r_web: '',
      r_logo: 0,
      r_image: 0,
      r_currency_sign: '',
      votes_avg: 0.0,
      votes_count: 0,
      r_display_logo: '4',
      premium_active: true,
      premium_preview: false,
      maintenance_active: false
    },
    r_cuisine_usages: [],
    r_menu_category_usages: [],
    r_allergen_types_restaurant: [],
    r_menu_menus: [],
    r_menu_items: [],
    r_images: [],
    r_gallery_images: [],
    r_languages: [],
    r_time_frames: [],
    order: {
      r_id: 0,
      items: []
    },
    cart: {
      r_id: 0,
      items: []
    }
  },
  getters: {
    getJWT: (state) => state.currentJWT,
    // getAPI: state => 'http://garagu.localhost/restaurania/api/',
    // getMediaURI: state => 'http://garagu.localhost/restaurania/image/',
    getAPI: () =>
      process.env.NODE_ENV === 'production'
        ? 'https://api.restaurania.com/'
        : 'http://garagu.localhost/restaurania/api/',
    getMediaURI: () =>
      process.env.NODE_ENV === 'production'
        ? 'https://service.restaurania.com/image/'
        : 'http://garagu.localhost/restaurania/image/',
    getPromoAgentCode: (state) => state.promoAgentCode,
    getUser: (state) => state.user,
    getAgent: (state) => state.agent,
    getGeoLocationStatus: (state) => state.geoLocationStatus,
    getVisibleHome: (state) => state.visibleHome,
    getCuisine: (state) => state.cuisine,
    getAllergens: (state) => state.allergens,
    getRestaurant: (state) => state.restaurants,
    getCuisineUsages: (state) => state.r_cuisine_usages,
    getMenuCategoryUsages: (state) => state.r_menu_category_usages,
    getAllergensRestaurant: (state) => state.r_allergen_types_restaurant,
    getMenues: (state) => state.r_menu_menus,
    getMenuItems: (state) => state.r_menu_items,
    getImages: (state) => state.r_images,
    getGalleryImages: (state) => state.r_gallery_images,
    getLanguages: (state) => {
      if (state.r_languages.length > 0) {
        return state.r_languages
      }
      return ['es']
    },
    getCurrentLanguage: (state) => state.currentLanguage,
    getTimeFrames: (state) => state.r_time_frames,
    getCurrency: (state) => {
      return state.restaurants.r_currency_sign.length
        ? state.restaurants.r_currency_sign
        : '€'
    },
    getFilters: (state) => state.filters,
    getCuisineFilters: (state) => state.filters.cuisine,
    getLimitToLocation: (state) => state.filters.limitToLocation,
    getUseLocation: (state) => state.filters.useLocation,
    getLocationSettings: (state) => {
      return {
        useLocation: state.filters.useLocation,
        limitToLocation: state.filters.limitToLocation,
        locationDistance: state.filters.locationDistance,
        location: state.filters.location
      }
    },
    getLocation: (state) => {
      return state.filters.location
    },
    getDestinationSettings: (state) => {
      return {
        useDestination: state.filters.useDestination,
        destinationID: state.filters.destinationID,
        destinationText: state.filters.destinationText,
        destinationBounds: state.filters.destinationBounds,
        destinationLocation: state.filters.destinationLocation
      }
    },
    getBiz: (state, getters) => {
      return {
        restaurants: getters.getRestaurant,
        r_cuisine_usages: getters.getCuisineUsages,
        r_menu_category_usages: getters.getMenuCategoryUsages,
        r_allergen_types_restaurant: getters.getAllergensRestaurant,
        r_menu_menus: getters.getMenues,
        r_menu_items: getters.getMenuItems,
        r_images: getters.getImages,
        r_gallery_images: getters.getGalleryImages,
        r_languages: getters.getLanguages,
        r_time_frames: getters.getTimeFrames
      }
    },
    getID: (state) => state.restaurants.r_id,
    isPremium: (state) => state.restaurants.premium_active,
    hasMaintenance: (state) => state.restaurants.maintenance_active,
    canPreview: (state) => state.restaurants.premium_preview,
    showPremium: (state) => state.showPremium,
    getCart: (state) => state.cart,
    getCartItems: (state) => state.cart.items,
    getCartLastIdItem: (state) => {
      let lastId = 0
      state.cart.items.forEach((element) => {
        if (element.id > lastId) lastId = element.id
      })
      return lastId
    },
    getOrder: (state) => state.order,
    getOrderLastIdItem: (state) => {
      let lastId = 0
      state.order.items.forEach((element) => {
        if (element.id > lastId) lastId = element.id
      })
      return lastId
    },

    getShowSearchNavBar: (state) => state.filters.showSearchNavBar,
    getFilterStatus: (state) => state.filters.status,
    getGetWindowWidth: (state) => state.device.windowWidth,
    getWindowType: (state) => {
      if (state.device.windowWidth <= state.screenTypes.xs) {
        return 'xs'
      }
      if (state.device.windowWidth <= state.screenTypes.sm) {
        return 'sm'
      }
      if (state.device.windowWidth <= state.screenTypes.md) {
        return 'md'
      }
      if (state.device.windowWidth <= state.screenTypes.lg) {
        return 'lg'
      }
      if (state.device.windowWidth <= state.screenTypes.xl) {
        return 'xl'
      }
    },
    isMobil: (state) => {
      if (state.device.windowWidth <= state.screenTypes.xs) {
        return true
      }
      if (state.device.windowWidth <= state.screenTypes.sm) {
        return true
      }
      return false
    },
    getCookieNotified: (state) => {
      return state.cookieNotified
    },
    getCookieVisible: (state) => {
      return state.cookieVisible
    },
    getLoginDialog: (state) => {
      return state.loginDialog
    },
    getAuthHeader: (state) => {
      return `Bearer ${state.currentJWT}`
    }
  },
  mutations: {
    setWindowWidth (state, value) {
      state.device.windowWidth = value
    },
    setJWT (state, jwt) {
      state.currentJWT = jwt
      if (state.currentJWT && state.currentJWT !== '') {
        localStorage.setItem('tokenJWT', state.currentJWT)
        const jwtData = JSON.parse(atob(state.currentJWT.split('.')[1]))
        state.user = {
          id: jwtData.user.id,
          level: jwtData.user.level,
          firstName: jwtData.user.name,
          lastName: jwtData.user.surname,
          image: '/assets/img/user_test.png'
        }
      } else {
        localStorage.removeItem('tokenJWT')
        state.user = {
          id: 0,
          level: 0,
          firstName: '',
          lastName: '',
          image: ''
        }
      }
    },
    setCurrentLanguage (state, lang) {
      const langstr = lang || 'en'
      state.currentLanguage = langstr
      localStorage.setItem('language', state.currentLanguage)
    },
    setPromoAgentCode (state, value) {
      if (value && value.length === 8) {
        state.promoAgentCode = value
        localStorage.setItem('promoAgentCode', value)
      }
    },
    cookieNotified (state, value) {
      state.cookieNotified = value
      localStorage.setItem('cookieNotified', value)
      if (value) {
        state.cookieVisible = true
      }
    },
    setCookieVisible (state, value) {
      state.cookieVisible = value
    },
    setAgent (state, value) {
      if (value.ra_id) {
        state.agent = value
      }
    },
    setFilters (state, value) {
      state.filters = value
    },
    setVisibleHome (state, value) {
      state.visibleHome = value
    },
    clearFilters (state) {
      state.filters.status = true
      state.filters.q = ''
      state.filters.takeAway = false
      state.filters.priceRange = false
      state.filters.text = ''
      state.filters.useLocation = false
      state.filters.limitToLocation = false
      state.filters.locationDistance = state.filters.defaultLocationDistance
      state.filters.mapBounds = null
      state.filters.useDestination = false
      state.filters.destinationID = 0
      state.filters.destinationText = ''
      state.filters.destinationBounds = {}
      state.filters.destinationLocation = {}
      state.filters.cuisine = []
    },
    setMapBounds (state, value) {
      state.filters.mapBounds = value
    },
    setUseLocation (state, value) {
      state.filters.useLocation = value
    },
    setLimitToLocation (state, value) {
      state.filters.limitToLocation = value
    },
    setLocation (state, value) {
      state.filters.location = value
    },
    setLocationDistance (state, value) {
      state.filters.locationDistance = value
    },
    setFilterQuery (state, value) {
      state.filters.q = value
    },
    setFilterText (state, value) {
      state.filters.text = value
    },
    setFilterCuisine (state, value) {
      const arrCuisinesSelect = state.filters.cuisine.find(
        (element) => element.id === value.id
      )
      if (!arrCuisinesSelect) {
        state.filters.cuisine.push({
          id: value.id,
          name: value.name,
          seleted: true
        })
      }
    },
    setFilterTakeAway (state, value) {
      state.filters.takeAway = value
    },
    setFilterIsOpen (state, value) {
      state.filters.isOpen = value
    },
    setFilterPriceRange (state, value) {
      state.filters.priceRange = value
    },
    removeFilterCuisine (state, value) {
      state.filters.cuisine = state.filters.cuisine.filter(
        (element) => element.id !== value.id
      )
    },
    setUseDestination (state, value) {
      state.filters.useDestination = value
    },
    setDestinationID (state, value) {
      state.filters.destinationID = value
    },
    setDestinationText (state, value) {
      state.filters.destinationText = value
    },
    setDestinationBounds (state, value) {
      state.filters.destinationBounds = value
    },
    setDestinationLocation (state, value) {
      state.filters.destinationLocation = value
    },
    setCuisine (state, values) {
      if (values && values.length > 0) {
        state.cuisine = values
      }
    },
    setAllergens (state, values) {
      if (values && values.length > 0) {
        state.allergens = values
      }
    },
    setRestaurant (state, value) {
      if (value.restaurants) {
        state.restaurants = value.restaurants
        state.showPremium = state.restaurants.premium_active
      }
      if (value.r_cuisine_usages) {
        state.r_cuisine_usages = value.r_cuisine_usages
      }
      if (value.r_menu_category_usages) {
        state.r_menu_category_usages = value.r_menu_category_usages
      }
      if (value.r_menu_items) {
        state.r_menu_items = value.r_menu_items
      }
      if (value.r_images) {
        state.r_images = value.r_images
      }
      if (value.r_gallery_images) {
        state.r_gallery_images = value.r_gallery_images
      }
      if (value.r_languages) {
        state.r_languages = value.r_languages
      }
      if (value.r_allergen_types_restaurant) {
        state.r_allergen_types_restaurant = value.r_allergen_types_restaurant
      }
      if (value.r_menu_menus) {
        state.r_menu_menus = value.r_menu_menus
      }
      if (value.r_time_frames) {
        state.r_time_frames = value.r_time_frames
      }
    },
    clearRestaurant (state) {
      state.restaurants = {
        r_id: -1,
        r_name_fb: '',
        r_name_lh: null,
        r_text_fb: '',
        r_text_lh: null,
        r_visible: 1,
        r_takeaway: 1,
        r_city: '',
        r_address: '',
        r_zip: '',
        r_phone: '',
        r_whatsapp: '',
        r_email: '',
        r_web: '',
        r_logo: 0,
        r_image: 0,
        r_currency_sign: '',
        r_display_logo: '4',
        premium_active: true,
        premium_preview: false,
        maintenance_active: false,
        votes_avg: 0.0,
        votes_count: 0
      }
      state.r_cuisine_usages = []
      state.r_menu_category_usages = []
      state.r_allergen_types_restaurant = []
      state.r_menu_menus = []
      state.r_menu_items = []
      state.r_images = []
      state.r_gallery_images = []
      state.r_languages = []
      state.r_time_frames = []
      state.showPremium = false
    },
    initCart (state, value) {
      if (state.cart.r_id !== value) {
        state.cart.items = []
      }
      state.cart.r_id = value
    },
    emptyCartItems (state) {
      state.cart.items = []
    },
    updateCartItem (state, value) {
      const cartItem = state.cart.items.find((item) => {
        return item.id === value.id
      })
      if (cartItem) {
        cartItem.amount += value.amount
      } else {
        state.cart.items.push(value)
      }
    },
    initOrder (state, value) {
      if (state.order.r_id !== value) {
        state.order.items = []
      }
      state.order.r_id = value
    },
    emptyOrderItems (state) {
      state.order.items = []
    },
    updateOrderItem (state, value) {
      const orderItem = state.order.items.find((item) => {
        return item.id === value.id
      })
      if (orderItem) {
        orderItem.amount += value.amount
      } else {
        state.order.items.push(value)
      }
    },
    removeCartItem (state, value) {
      state.cart.items = state.cart.items.filter((obj) => {
        return obj.id !== value
      })
    },
    removeOrderItem (state, value) {
      state.order.items = state.order.items.filter((obj) => {
        return obj.id !== value
      })
    },
    setShowSearchNavBar (state, value) {
      state.filters.showSearchNavBar = value
    },
    setFilterStatus (state, value) {
      state.filters.status = value
    },
    setLoginDialogType (state, value) {
      state.loginDialog.type = value
    },
    setLoginDialogVisibility (state, value) {
      state.loginDialog.visible = value
    },
    setShowPremium (state, value) {
      if (state.restaurants.premium_active) {
        state.showPremium = true
      } else {
        if (value === true && (state.restaurants.premium_preview || state.user.level >= 9)) {
          state.showPremium = true
        } else {
          state.showPremium = false
        }
      }
    }
  },
  actions: {
    userLogout (context) {
      context.commit('setJWT', null)
      delete Vue.http.headers.common.Authorization
    },
    async refreshJWT (context) {
      if (context.getters.getJWT && context.getters.getJWT !== 'undefined') {
        Vue.http.headers.common.Authorization = context.getters.getAuthHeader
        return await Vue.http.get('user/refresh/').then(
          (response) => {
            if (!response.body.error && 'jwt' in response.body) {
              context.commit('setJWT', response.body.jwt)
              Vue.http.headers.common.Authorization =
                context.getters.getAuthHeader
            } else {
              context.dispatch('userLogout')
              console.log('err', response.body.error)
            }
          },
          (error) => {
            console.log(error.body ? error.body : 'userRefresh error')
          }
        )
      } else {
        context.dispatch('userLogout')
      }
    },
    async processCurrentLanguage (context, languages) {
      if (typeof languages === 'string') {
        context.commit('setCurrentLanguage', languages)
      } else if (!context.getters.getCurrentLanguage) {
        const language = Vue.prototype.appLanguages.find((sl) =>
          languages.find((l) =>
            l.split(new RegExp(sl, 'gi')).length - 1 > 0 ? sl : null
          )
        )
        context.commit('setCurrentLanguage', language)
      }
    },
    cookieNotify (context) {
      if (!context.getters.getCookieNotified) {
        context.commit('cookieNotified', true)
      }
    },
    hideLogin (context) {
      context.commit('setLoginDialogVisibility', false)
    },
    showLogin (context) {
      context.commit('setLoginDialogType', 0)
      context.commit('setLoginDialogVisibility', true)
    },
    showRegister (context) {
      context.commit('setLoginDialogType', 1)
      context.commit('setLoginDialogVisibility', true)
    },
    showResetAsk (context) {
      context.commit('setLoginDialogType', 2)
      context.commit('setLoginDialogVisibility', true)
    },
    showResetSet (context) {
      context.commit('setLoginDialogType', 3)
      context.commit('setLoginDialogVisibility', true)
    },
    showRegisterAgent (context) {
      context.commit('setLoginDialogType', 4)
      context.commit('setLoginDialogVisibility', true)
    },
    showRegisterBiz (context) {
      context.commit('setLoginDialogType', 5)
      context.commit('setLoginDialogVisibility', true)
    },
    async getPosition (context) {
      return new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(resolve, reject)
      )
        .then((result) => {
          context.commit('setLocation', {
            lat: result.coords.latitude,
            lng: result.coords.longitude
          })
          context.state.geoLocationStatus = true
          return true
        })
        .catch((err) => {
          context.commit('setLocation', null)
          context.state.geoLocationStatus = false
          console.log(err)
          return false
        })
    }
  },
  // plugins: [createPersistedState()],
  modules: {}
})
