<template>
  <div class="r-header">
    <!-- Logo -->
    <div
      class="r-header-logo"
      :class="{ 'r-header-logo-hidden': $route.name === 'biz' }"
    >
      <img
        alt="Restaurania"
        src="@/assets/img/logo_color.svg"
        class="pointer"
        @click="redirectBackToHome()"
      />
    </div>

    <!-- Languages -->
    <div
      class="r-header-langs"
      :class="{ 'r-header-langs-search': checkShowSearch }"
    >
      <el-dropdown @command="handleGlobalLanguage" trigger="click">
        <span class="r-header-langs-dropdown"
          >{{ $i18n.locale }}<i class="el-icon-d-caret"></i
        ></span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="lang in appLanguages"
            :command="lang"
            class="r-header-langs-item"
            :class="{ active: $i18n.locale === lang }"
            :key="`r-lang-${lang}`"
            >{{ lang }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <!-- Search Bar -->
    <template v-if="checkShowSearch">
      <div class="r-header-search">
        <input-search :expandable="true" />
      </div>
      <div class="r-header-overlay"></div>
    </template>

    <!-- Join Menu -->
    <!-- <template v-if="checkShowJoin">
      <div class="r-header-join">
        <el-button type="primary" size="medium">Try for free </el-button>
      </div>
    </template> -->

    <!-- Restaurant Nav Bar -->
    <div class="r-header-restaurant" v-else-if="$route.name === 'restaurant'">
      <div class="r-header-restaurant-name">
        {{ getRestaurantName }}
      </div>
      <div v-if="showPremium" class="r-header-restaurant-menu">
        <label
          for="r-header-restaurant-menu-check"
          class="r-header-restaurant-menu-title"
          >{{ $t('nav.menu') }}<i class="el-icon-arrow-down"></i
        ></label>
        <input
          type="checkbox"
          id="r-header-restaurant-menu-check"
          class="r-popup r-hidden"
        />
        <label for="r-header-restaurant-menu-check" class="r-popup-close-overlay"></label>
        <nav class="r-header-restaurant-menu-items">
          <a href="#r-section-home" class="r-header-restaurant-menu-items-item">
            <i class="el-icon-s-home"></i>
            <span>{{ $t('nav.home') }}</span>
          </a>
          <a
            href="#r-section-about"
            class="r-header-restaurant-menu-items-item"
          >
            <i class="el-icon-s-shop"></i>
            <span>{{ $t('general.aboutUs') }}</span>
          </a>
          <a
            v-if="menuoptions && menuoptions.menues"
            href="#r-section-menues"
            class="r-header-restaurant-menu-items-item"
          >
            <i class="el-icon-s-order"></i>
            <span>{{ $t('general.menus') }}</span>
          </a>
          <template v-if="menuoptions && menuoptions.menu">
            <a
              v-if="!menuoptions.sections"
              href="#r-section-menu"
              class="r-header-restaurant-menu-items-item"
            >
              <i class="el-icon-s-management"></i>
              <span>{{ $t('general.aLaCarteOurs') }}</span>
            </a>
            <template v-else>
              <div class="r-header-restaurant-menu-categories">
                <el-dropdown @command="handleChangeHash" trigger="click">
                  <span
                    class="el-dropdown-link r-header-restaurant-menu-items-item"
                  >
                    <i class="el-icon-s-management"></i>
                    <span
                      >{{ $t('general.aLaCarteOurs')
                      }}<i class="el-icon-arrow-down el-icon--right"></i
                    ></span>
                  </span>
                  <el-dropdown-menu class="r-header-restaurant-menu-categories-dropdown-menu" slot="dropdown">
                    <div class="scroll-if-needed">
                      <el-dropdown-item
                        v-for="category in menuoptions.categories"
                        :key="`submenu-section-cat-${category.rmcu_id}`"
                        :command="`#r-section-menu-cat-${category.rmcu_id}`"
                        >
                          {{ getText(category.rmcu_name_lh) }}
                        </el-dropdown-item>
                    </div>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <a
                v-for="category in menuoptions.categories"
                :key="`menu-section-cat-${category.rmcu_id}`"
                class="r-header-restaurant-menu-category r-header-restaurant-menu-items-item"
                :href="`#r-section-menu-cat-${category.rmcu_id}`"
                >
                <span>{{ getText(category.rmcu_name_lh) }}</span>
              </a>
            </template>
          </template>

          <a
            href="#r-section-contact"
            class="r-header-restaurant-menu-items-item"
          >
            <i class="el-icon-info"></i>
            <span>{{ $t('nav.contact') }}</span>
          </a>

          <div class="r-header-restaurant-languages">
            <el-dropdown @command="handleChangeLanguage" trigger="click">
              <span
                class="el-dropdown-link r-header-restaurant-menu-items-item"
              >
                <i class="el-icon-s-comment"></i>
                <span
                  >{{ selectedRestaurantLanguage
                  }}<i class="el-icon-arrow-down el-icon--right"></i
                ></span>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="lang in getLanguages"
                  :command="lang"
                  :key="`r-lang-${lang}`"
                  >{{ lang.toUpperCase() }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </nav>
        <div class="r-header-restaurant-menu-title r-header-restaurant-menu-title-languages">
          <el-dropdown @command="handleChangeLanguage" trigger="click">
              <span
                class="el-dropdown-link r-header-restaurant-menu-items-item"
              >
                <span
                  >{{ selectedRestaurantLanguage
                  }}<i class="el-icon-arrow-down el-icon--right"></i
                ></span>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="lang in getLanguages"
                  :command="lang"
                  :key="`r-lang-${lang}`"
                  >{{ lang.toUpperCase() }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
        </div>
      </div>
    </div>

    <!-- Manage Restaurant Nav Bar -->
    <div
      v-else-if="$route.name === 'bizs'"
      class="r-header-title"
    >
      {{ $t('nav.manageRestaurants') }}
    </div>

    <!-- User Actions -->
    <div class="r-header-menu">
      <div v-if="$route && $route.name && $route.name.includes('biz-') && !isPremium" class="r-header-menu-space">
        <el-button type="primary" round @click="changeRoute('biz-subs')">
          {{ $t('subs.goPremium') }}
        </el-button>
      </div>

      <!-- <el-button
        v-if="checkShowJoin"
        class="r-header-menu-join"
        icon="el-icon-plus"
        type="primary"
        round
        @click="changeRoute('join')"
        ><span>{{ $t('nav.addMyRestaurant') }}</span></el-button> -->

      <el-dropdown class="r-header-menu-space" trigger="click" @command="handleHeaderMenuCommand">
        <el-button
          icon="el-icon-plus"
          type="primary"
          circle
        />

        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :command="{ type: 'route', value: 'join' }">
            {{ $t('nav.ourRates') }}
          </el-dropdown-item>
          <el-dropdown-item :command="{ type: 'routepath', value: '/r/4' }">
            {{ $t('subs.joinDemo') }}
          </el-dropdown-item>
          <el-dropdown-item :command="isUserOnline ? { type: 'route', value: 'bizs' } : { type: 'action', value: 'registerbiz' }">
            {{ $t('nav.addMyRestaurant') }}
          </el-dropdown-item>
          <el-dropdown-item v-if="!isUserOnline || getUser.level === PERM_USER" divided :command="{ type: 'route', value: 'license' }">
            {{ $t('general.license') }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <el-dropdown trigger="click" @command="handleHeaderMenuCommand">
        <!-- <el-avatar v-if="isUserOnline" :src="getUser.image"></el-avatar> -->
        <el-avatar v-if="isUserOnline" class="ra-bkg-avatar">{{
          `${getUser.firstName.charAt(0).toUpperCase()}${getUser.lastName
            .charAt(0)
            .toUpperCase()}`
        }}</el-avatar>
        <el-avatar v-else icon="el-icon-user-solid"></el-avatar>

        <el-dropdown-menu slot="dropdown">
          <!-- Actions for log in users -->
          <template v-if="isUserOnline">
            <el-dropdown-item :command="{ type: 'route', value: 'me' }">
              {{ $t('general.myAccount') }}
            </el-dropdown-item>
            <el-dropdown-item :command="{ type: 'route', value: 'myorders' }">
              {{ $t('nav.myOrders') }}
            </el-dropdown-item>
            <el-dropdown-item
              :command="{ type: 'route', value: 'myfavorites' }"
            >
              {{ $t('nav.myRestaurants') }}
            </el-dropdown-item>

            <el-dropdown-item
              v-if="getUser.level >= PERM_BIZ"
              divided
              :command="{ type: 'route', value: 'bizs' }"
            >
              {{ $t('nav.manageRestaurants') }}
            </el-dropdown-item>
            <el-dropdown-item
              v-else
              divided
              :command="{ type: 'route', value: 'bizs' }"
            >
              {{ $t('nav.addMyRestaurant') }}
            </el-dropdown-item>

            <template v-if="getUser.level >= PERM_AGENT">
              <el-dropdown-item
                divided
                :command="{ type: 'route', value: 'agent-dashboard' }"
              >
                {{ $t('nav.salesAgent') }}
              </el-dropdown-item>
            </template>

            <template v-if="getUser.level >= PERM_ADMIN">
              <el-dropdown-item
                divided
                :command="{ type: 'route', value: 'admin-dashboard' }"
              >
                Admin
              </el-dropdown-item>
            </template>

            <el-dropdown-item
              divided
              :command="{ type: 'route', value: 'contact' }"
            >
              {{ $t('nav.contact') }}
            </el-dropdown-item>

            <el-dropdown-item
              divided
              :command="{ type: 'action', value: 'logout' }"
            >
              {{ $t('nav.logOut') }}
            </el-dropdown-item>
          </template>

          <!-- Actions for Guest -->
          <template v-else>
            <el-dropdown-item :command="{ type: 'action', value: 'register' }">
              {{ $t('nav.loginType1') }}
            </el-dropdown-item>
            <el-dropdown-item :command="{ type: 'action', value: 'login' }">
              {{ $t('nav.loginType0') }}
            </el-dropdown-item>
          </template>

          <!-- Actions for all -->
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <!-- Log In Modal Dialog -->
    <login-modal />
  </div>
</template>

<script>
// import Vue from 'vue'
import { PERM_BIZ, PERM_ADMIN, PERM_AGENT, PERM_USER } from '../constants'
import { mapGetters, mapActions } from 'vuex'
import InputSearch from './InputSearch.vue'
import LoginModal from './Login.vue'

export default {
  components: {
    InputSearch,
    LoginModal
  },
  props: {
    menuoptions: { type: Object, default: null },
    showlogin: { type: Boolean, default: false }
  },
  data () {
    return {
      showLoginModal: false,
      showRegisterModal: false,
      rSelectedLanguage: ''
    }
  },
  watch: {
    showlogin () {
      if (this.showlogin) {
        this.showLogin()
      }
    }
  },
  computed: {
    ...mapGetters([
      'getUser',
      'getRestaurant',
      'getLanguages',
      'getCuisine',
      'getFilters',
      'getAuthHeader',
      'getWindowType',
      'getShowSearchNavBar',
      'getVisibleHome',
      'getGeoLocationStatus',
      'getCurrentLanguage',
      'isPremium',
      'showPremium'
    ]),
    PERM_USER () {
      return PERM_USER
    },
    PERM_BIZ () {
      return PERM_BIZ
    },
    PERM_ADMIN () {
      return PERM_ADMIN
    },
    PERM_AGENT () {
      return PERM_AGENT
    },
    isUserOnline () {
      return this.getUser.id !== 0
    },
    checkShowSearch () {
      if (
        (this.getShowSearchNavBar || !this.getVisibleHome) &&
        this.$route.name === 'home'
      ) {
        return true
      }
      return false
    },
    checkShowJoin () {
      return this.$route.name !== 'join'
    },
    selectedRestaurantLanguage () {
      let lang = this.rSelectedLanguage
      if (
        !this.getLanguages.find(
          (item) => item.toLowerCase() === this.rSelectedLanguage
        )
      ) {
        lang = this.getLanguages[0]
      }
      this.$emit('language', lang)
      return lang
    },
    getRestaurantName () {
      const handle = this.getRestaurant.r_name_lh
      if (handle && handle.fb) {
        if (
          handle.values &&
          handle.values[this.rSelectedLanguage] &&
          handle.values[this.rSelectedLanguage].trim() !== ''
        ) {
          return handle.values[this.rSelectedLanguage].trim()
        } else if (handle.fb !== '') {
          return handle.fb
        }
      }
      return this.getRestaurant.r_name_fb
    }
  },
  created () {
    this.handleChangeLanguage(this.getCurrentLanguage)
  },
  methods: {
    ...mapActions([
      'userLogout',
      'showLogin',
      'showRegister',
      'showRegisterBiz',
      'processCurrentLanguage'
    ]),
    handleHeaderMenuCommand (obj) {
      if (!obj) {
        return
      }
      switch (obj.type) {
        case 'action':
          switch (obj.value) {
            case 'login':
              this.showLogin()
              break
            case 'register':
              this.showRegister()
              break
            case 'registerbiz':
              this.showRegisterBiz()
              break
            case 'logout':
              this.userLogout()
              this.$router.push({ name: 'home' })
              break
          }
          break
        case 'route':
          this.changeRoute(obj.value)
          break
        case 'routepath':
          this.$router
            .push({
              path: obj.value
            })
            .catch(() => {})
          break
      }
    },
    changeRoute (routeName) {
      this.$router
        .push({
          name: routeName
        })
        .catch(() => {})
    },
    redirectBackToHome () {
      this.$store.commit('clearFilters')
      this.$store.commit('setVisibleHome', true)
      // if (this.getGeoLocationStatus) {
      //   this.$store.commit('setLimitToLocation', true)
      //   this.$store.commit('setUseLocation', true)
      // }
      this.changeRoute('home')
    },
    handleChangeHash (value) {
      this.$router.push({
        hash: value
      })
    },
    handleChangeLanguage (value) {
      this.rSelectedLanguage = value
    },
    handleGlobalLanguage (value) {
      this.processCurrentLanguage(value)
      if (this.$i18n.locale !== this.getCurrentLanguage) {
        this.$i18n.locale = this.getCurrentLanguage
        this.handleChangeLanguage(value)
      }
    },
    getText (handle) {
      if (handle && handle.fb) {
        if (this.showPremium) {
          if (
            handle.values &&
            handle.values[this.getCurrentLanguage] &&
            handle.values[this.getCurrentLanguage].trim() !== ''
          ) {
            return handle.values[this.getCurrentLanguage].trim()
          } else if (handle.fb !== '') {
            return handle.fb
          }
        } else {
          if (handle.fb !== '') {
            return handle.fb
          } else if (
            handle.values &&
            handle.values[this.getCurrentLanguage] &&
            handle.values[this.getCurrentLanguage].trim() !== ''
          ) {
            return handle.values[this.getCurrentLanguage].trim()
          }
        }
      }
      return ''
    }
  }
}
</script>

<style lang="less">
@import url('../assets/css/variables.less');

.r-header {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  & > div {
    padding-right: 20px;
    @media (max-width: @break-mobile) {
      padding-right: 10px;
    }
  }
  &-langs {
    position: absolute;
    left: 59px;
    top: 2px;
    &-dropdown {
      text-transform: uppercase;
      cursor: pointer;
      color: #b3b3b3;
      font-size: 12px;
      padding-bottom: 10px; // make click area bigger
    }
    &-item {
      text-transform: uppercase;
      &.active {
        color: @color-primary;
        pointer-events: none;
      }
    }
  }
  &-logo {
    padding-right: 0 !important;
    a {
      text-decoration: none;
      color: white;
    }
    img {
      height: 60px;
    }
    &-hidden {
      @media (max-width: @break-tablet) {
        visibility: hidden;
      }
    }
  }
  &-title {
    color: white;
  }
  &-search {
    margin-left: 35px;
    flex-grow: 2;
    input::placeholder {
      @media (max-width: @break-mobile) {
        visibility: hidden;
      }
    }
    &:focus-within + .r-header-overlay {
      opacity: 0.65;
      pointer-events: all;
    }
    & ~ .r-header-menu {
      .r-header-menu-join {
        @media (max-width: @break-mobile) {
          display: none;
        }
      }
    }
  }
  &-join {
    position: absolute;
    right: 59px;
    color: white;
  }
  &-overlay {
    // @media (max-width: @break-mobile) {
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 60px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background: black;
    transition: opacity 0.2s;
    // }
  }
  &-menu {
    background: transparent;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    .el-dropdown-link {
      color: white;
      font-size: 24px;
    }
    &-space {
      margin-right: 10px;
    }
  }
  &-restaurant {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    flex-grow: 1;
    &-name {
      font-family: 'Montserrat', sans-serif;
      color: white;
      max-width: 500px;
      overflow: hidden;
      font-size: 120%;
      @media (max-width: @break-mobile) {
        display: none;
      }
    }
    &-languages {
      display: inline-block;
      cursor: pointer;
      @media (max-width: @break-tablet) {
        display: none;
        // display: block;
        // border-top: 1px solid #2a3b44;
        // padding: 10px 0;
      }
      .el-dropdown {
        font-family: 'Montserrat', sans-serif;
        color: white;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 10px;
      }
    }
    &-menu-categories {
      display: inline-block;
      cursor: pointer;
       @media (max-width: @break-tablet) {
        display: none;
      }
      .el-dropdown {
        font-family: 'Montserrat', sans-serif;
        color: white;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 10px;
      }
    }
    &-menu-category.r-header-restaurant-menu-items-item {
      display: none;
      @media (max-width: @break-tablet) {
        display: block;
      }
    }
    &-menu {
      flex: 1 1 0;
      text-align: center;
      white-space: nowrap;
      padding-left: 26px;
      &-title,
      a {
        &,
        &:active,
        &:visited,
        &:focus {
          font-family: 'Montserrat', sans-serif;
          color: white;
          text-transform: uppercase;
          text-decoration: none;
          display: inline-block;
          transition: 0.3s;
        }
        &:hover,
        &:active {
          color: #ff9933;
        }
      }
      &-title {
        display: none;
        .r-popup-close-overlay {
          display: none;
        }
        @media (max-width: @break-tablet) {
          display: inline-block;
          + input[type='checkbox']:checked {
            ~ .r-header-restaurant-menu-items {
              display: block;
              position: absolute;
              background: lighten(#2a3b44, 10%);
              width: 240px;
              left: calc(50% - 120px);
              top: @header_height - 10px;
              border-radius: 4px;
              box-shadow: 0 0 5px #000;
              box-sizing: border-box;
              padding: 10px 0;
              max-height: calc(90vh - @header_height);
              overflow-y: auto;
              a {
                display: block;
                text-align: center;
                padding: 10px 0;
                &:nth-child(n + 2) {
                  border-top: 1px solid #2a3b44;
                }
              }
            }
            ~ .r-popup-close-overlay {
              display: block;
              position: fixed;
              left: 0;
              top: 0;
              right: 0;
              bottom: 0;
              // background: rgba(0,0,0,0.5);
            }
          }
        }
        &-languages {
          margin-left: 20px;
          span {
            color: #fff;
          }
        }
      }
      &-items {
        @media (max-width: @break-tablet) {
          display: none;
        }
        &-item {
          display: inline-block;
          text-align: center;
          margin: 0 10px;
          @media (max-width: @break-tablet) {
            display: block;
          }
          & > i {
            color: white;
            font-size: 32px;
            opacity: 0.65;
            transition: 0.2s;
            @media (max-width: @break-tablet) {
              display: none;
            }
          }
          & > span {
            display: block;
            font-size: 10px;
            margin-top: 2px;
            transition: 0.2s;
            @media (max-width: @break-tablet) {
              display: inline;
              font-size: 16px;
            }
          }
          &:hover {
            & > i {
              color: white;
              opacity: 1;
            }
            & > span {
              color: @color-primary;
            }
          }
        }
      }
    }
  }
}

// .r-header .el-dialog {
//   margin-top: 25vh !important;
//   width: 90% !important;
//   max-width: 550px !important;
// }
</style>
