<template>
  <div id="app" class="default-min-width">
    <el-container>
      <el-header class="default-min-width">
        <nav-bar
          @language="language = $event"
          :menuoptions="menuoptions"
          :showlogin="showlogin"
        ></nav-bar>
      </el-header>
      <div>
        <router-view
          :language="language"
          @menuoptionsset="menuoptions = $event"
          @showlogin="showlogin = $event"
        />
      </div>
    </el-container>
    <div v-if="getCookieVisible" class="cookie-notify">
      <p>
        {{ $t('general.cookieAdvise') }}
      </p>
      <el-button
        type="success"
        icon="el-icon-check"
        size="medium"
        @click="closeCookieNotify"
        >{{ $t('general.close') }}</el-button
      >
      <span class="cookie-icon">🍪</span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import NavBar from './components/NavBar.vue'
export default {
  components: {
    NavBar
  },
  data () {
    return {
      showlogin: false,
      language: '',
      menuoptions: {
        menues: false,
        menu: false,
        sections: false,
        categories: []
      }
    }
  },
  computed: {
    ...mapGetters([
      'getCuisine',
      'getAllergens',
      'getLocation',
      'getCookieVisible',
      'getCurrentLanguage',
      'getPromoAgentCode'
    ])
  },
  created () {
    this.cookieNotify()
    if (this.$route.query.agent && this.$route.query.agent.length === 8) {
      this.$store.commit('setPromoAgentCode', this.$route.query.agent)
    }
    this.processCurrentLanguage(navigator.languages).then(() => {
      if (this.$i18n.locale !== this.getCurrentLanguage) {
        this.$i18n.locale = this.getCurrentLanguage
      }
    })
    if (!this.getCuisine.length) {
      this.$http.post('info/cuisine/').then(
        (response) => {
          if (!response.body.error) {
            this.$store.commit('setCuisine', response.body)
          } else {
            console.log('err', response.body.error)
          }
        },
        (error) => {
          console.log(error.body ? error.body : 'info cuisine error')
        }
      )
    }
    if (!this.getAllergens.length) {
      this.$http.post('info/allergens/').then(
        (response) => {
          if (!response.body.error) {
            this.$store.commit('setAllergens', response.body)
          } else {
            console.log('err', response.body.error)
          }
        },
        (error) => {
          console.log(error.body ? error.body : 'info allergens error')
        }
      )
    }
    this.sendNewVisit()
  },
  mounted () {
    this.$store.commit('setWindowWidth', window.innerWidth)
    window.addEventListener('resize', () => {
      this.$store.commit('setWindowWidth', window.innerWidth)
    })
  },
  methods: {
    ...mapActions(['cookieNotify', 'processCurrentLanguage']),
    closeCookieNotify () {
      this.$store.commit('setCookieVisible', false)
    },
    sendNewVisit () {
      this.$http
        .post('stats/new_visit', {
          type: 'site',
          device: this.$device.machine.type
        })
        .then(
          (response) => {},
          (error) => {
            console.log(error.body ? error.body : 'stat error')
          }
        )
    }
  }
}
</script>

<style lang="less" scoped>
.cookie-notify {
  position: fixed;
  bottom: 10px;
  left: 10px;
  width: 300px;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.65);
  z-index: 99;
  background: white;
  padding: 20px;
  margin: 0;
  overflow: hidden;
  p {
    padding: 0;
    font-size: 90%;
    margin: 0 0 20px 0;
    line-height: 140%;
    text-shadow: 1px 0 0 white, -1px 0 0 white, 0 1px 0 white, 0 -1px 0 white,
      1px 1px 0 white, -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white;
  }
  .cookie-icon {
    font-size: 200px;
    line-height: 200px;
    pointer-events: none;
    z-index: -1;
    position: absolute;
    right: -50px;
    bottom: -50px;
    opacity: 0.5;
  }
}
</style>

<style lang="less">
@import url('./assets/css/default.less');

#app {
  color: @color-font;
  padding-top: @header_height;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header.el-header {
  background: @color-secondary;
  box-shadow: 0 0 5px #000;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: @header_height;
  z-index: 5;
}

// .v-modal {
//   z-index: 2 !important;
// }

/*
// TODO: no me gusta que haya que definirlo
.el-select .el-input {
  width: 150px;
  @media (max-width: @break-tablet) {
    width: 38px;
  }
}

.el-transfer-panel__item.el-checkbox {
  width: calc(100% - 30px);
  box-sizing: border-box;
}

.is-scrolling-left,
.is-scrolling-middle,
.is-scrolling-right {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #f5f7fa;
    // border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #909399;
    // border-radius: 10px;
  }
}
*/

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Noto Sans', Ubuntu, Cantarell, 'Helvetica Neue', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  min-height: 100vh;
  max-width: 100vw;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  * {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      'Noto Sans', Ubuntu, Cantarell, 'Helvetica Neue', sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
      'Noto Color Emoji';
  }
  *:focus {
    outline: none;
  }
  .el-main {
    padding: 0;
  }
  .el-message {
    max-width: 280px;
    margin-top: 60px;
    left: 100%;
    transform: translateX(calc(-100% - 20px));
  }
  .r-hidden {
    height: 0 !important;
    width: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    visibility: hidden !important;
    display: none !important;
  }

  .el-scrollbar {
    padding: 0;
  }

  .el-input input[type='text'],
  .el-select__input,
  input[type='text'],
  .el-date-editor input.el-range-input {
    @media (max-width: @break-mobile) {
      font-size: 16px; // important for iPhone
    }
  }

  .r-html-editor {
    border-radius: 4px;
    line-height: normal;
    border: 1px solid @color-border;
    transition: 0.3s;
    color: @color-font;
    &:hover {
      border-color: @color-border-hover;
    }
    &:focus-within {
      border-color: @color-primary;
    }
    .ql {
      &-toolbar {
        border: none;
        border-bottom: 1px solid @color-border;
        background: @color-fill;
        border-radius: 4px 4px 0 0;
        padding-bottom: 0px !important;
        button {
          outline: none;
          transition: 0.3s;
          .ql-stroke,
          .ql-fill {
            transition: 0.3s;
          }
          &:hover,
          &:focus,
          &.ql-active {
            color: @color-primary;
            .ql-stroke {
              stroke: @color-primary;
            }
            .ql-fill {
              fill: @color-primary;
            }
          }
        }
      }
      &-container {
        border: none;
        border-radius: 0 0 4px 4px;
      }
      &-editor {
        color: @color-font;
        @media (min-width: @break-tablet) {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
