<template>
  <el-dialog
    v-if="getLoginDialog.visible"
    :visible="getLoginDialog.visible"
    :append-to-body="true"
    :lock-scroll="true"
    :title="$t(`nav.loginType${getLoginDialog.type}`)"
    :class="`ra-dialog-login ra-dialog-login-type-${getLoginDialog.type}`"
    @close="hideLogin"
  >
    <!-- Login -->
    <div class="ra-dialog-login-content" v-if="getLoginDialog.type === 0">
      <el-form :model="loginForm" label-position="top">
        <el-form-item :label="$t('nav.user')">
          <el-input
            maxlength="255"
            v-model="loginForm.user"
            suffix-icon="el-icon-user"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('nav.password')">
          <el-input
            maxlength="255"
            type="password"
            v-model="loginForm.password"
            suffix-icon="el-icon-key"
          ></el-input>
        </el-form-item>
      </el-form>

      <div class="ra-dialog-login-link">
        <el-button type="text" @click="showRegister">{{
          $t('nav.loginTypeLink0')
        }}</el-button>
      </div>
      <div class="ra-dialog-login-link">
        <el-button type="text" @click="showResetAsk">{{
          $t('nav.loginTypeLink2')
        }}</el-button>
      </div>
    </div>

    <div class="ra-dialog-login-content" v-else-if="getLoginDialog.type === 1 || getLoginDialog.type === 4 || getLoginDialog.type === 5">
      <el-alert
        :title="errMsgRegisterForm"
        type="error"
        v-if="errMsgRegisterForm !== ''"
      >
      </el-alert>

      <el-form
        :model="registerForm"
        :rules="rulesRegisterForm"
        ref="registerForm"
        label-position="top"
      >
        <el-form-item :label="$t('nav.user')" prop="email">
          <el-input
            maxlength="255"
            v-model="registerForm.email"
            size="medium"
            ref="refEmail"
            auto-complete="off"
            :placeholder="$t('general.email')"
            suffix-icon="el-icon-user"
          >
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('nav.password')" prop="password">
          <el-input
            maxlength="255"
            type="password"
            v-model="registerForm.password"
            size="medium"
            auto-complete="off"
            suffix-icon="el-icon-key"
          >
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('nav.passwordRepeat')" prop="password2">
          <el-input
            maxlength="255"
            show-word-limit
            type="password"
            v-model="registerForm.password2"
            size="medium"
            auto-complete="off"
            suffix-icon="el-icon-key"
          >
          </el-input>
        </el-form-item>
        <br />
        <el-form-item :label="$t('general.name')" prop="name">
          <el-input
            maxlength="255"
            v-model="registerForm.name"
            size="medium"
            suffix-icon="el-icon-edit"
          >
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('user.surName')" prop="surname">
          <el-input
            maxlength="255"
            v-model="registerForm.surname"
            size="medium"
            suffix-icon="el-icon-edit"
          >
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('general.phone')" prop="phone">
          <el-input
            maxlength="255"
            v-model="registerForm.phone"
            size="medium"
            suffix-icon="el-icon-edit"
          >
          </el-input>
        </el-form-item>
      </el-form>

      <div class="ra-dialog-login-link">
        <el-button type="text" @click="showLogin">{{
          $t('nav.loginTypeLink1')
        }}</el-button>
      </div>
    </div>

    <div class="ra-dialog-login-content" v-if="getLoginDialog.type === 2">
      <el-alert
        :title="resetMessage.text"
        :type="resetMessage.type"
        v-if="resetMessage.text.length > 0"
      >
      </el-alert>

      <el-form :model="resetAskForm" :rules="rulesResetAskForm" ref="resetAskForm" label-position="top">
        <el-form-item :label="$t('nav.user')">
          <el-input
            maxlength="255"
            v-model="resetAskForm.user"
            suffix-icon="el-icon-user"
          ></el-input>
        </el-form-item>
      </el-form>

      <div class="ra-dialog-login-link">
        <el-button type="text" @click="showRegister">{{
          $t('nav.loginTypeLink0')
        }}</el-button>
      </div>
    </div>

    <div class="ra-dialog-login-content" v-if="getLoginDialog.type === 3">
      <el-alert
        :title="resetMessage.text"
        :type="resetMessage.type"
        v-if="resetMessage.text.length > 0"
      >
      </el-alert>

      <el-form :model="resetSetForm" :rules="rulesResetSetForm" ref="resetSetForm" label-position="top">
        <el-form-item :label="$t('nav.password')" prop="password">
          <el-input
            maxlength="255"
            type="password"
            v-model="resetSetForm.password"
            size="medium"
            auto-complete="off"
            suffix-icon="el-icon-key"
          >
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('nav.passwordRepeat')" prop="password2">
          <el-input
            maxlength="255"
            show-word-limit
            type="password"
            v-model="resetSetForm.password2"
            size="medium"
            auto-complete="off"
            suffix-icon="el-icon-key"
          >
          </el-input>
        </el-form-item>
      </el-form>

      <div class="ra-dialog-login-link">
        <el-button type="text" @click="showLogin">{{
          $t('nav.loginTypeLink1')
        }}</el-button>
      </div>
    </div>

    <div class="ra-dialog-navigation">
      <div class="ra-dialog-navigation-item-left">
        <el-button
          type="text"
          size="medium"
          icon="el-icon-close"
          @click="hideLogin"
          >{{ $t('general.close') }}</el-button
        >
      </div>
      <div class="ra-dialog-navigation-item">
        <el-button
          v-if="getLoginDialog.type === 0"
          type="primary"
          size="medium"
          :loading="loading"
          @click="userLogin"
          >{{ $t('nav.loginType0') }}</el-button
        >
        <el-button
          v-else-if="getLoginDialog.type === 1 || getLoginDialog.type === 4 || getLoginDialog.type === 5"
          type="primary"
          size="medium"
          :loading="loading"
          @click="userRegister"
          >{{ $t('nav.loginType1') }}</el-button
        >
        <el-button
          v-else-if="getLoginDialog.type === 2"
          type="primary"
          size="medium"
          :loading="loading"
          @click="userResetAsk"
          >{{ $t('nav.loginType2') }}</el-button
        >
        <el-button
          v-else-if="getLoginDialog.type === 3"
          type="primary"
          size="medium"
          :loading="loading"
          @click="userResetSet"
          >{{ $t('nav.loginType3') }}</el-button
        >
      </div>
    </div>
    <div class="ra-dialog-navigation-shadow" />
  </el-dialog>
</template>
<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
export default {
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('nav.passwordHint')))
      } else {
        if (this.registerForm.password2 !== '') {
          this.$refs.registerForm.validateField('password2')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('nav.passwordRepeatHint')))
      } else if (value !== this.registerForm.password) {
        callback(new Error(this.$t('nav.passwordRepeatError')))
      } else {
        callback()
      }
    }
    var validatePassReset = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('nav.passwordHint')))
      } else {
        if (this.resetSetForm.password2 !== '') {
          this.$refs.resetSetForm.validateField('password2')
        }
        callback()
      }
    }
    var validatePass2Reset = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('nav.passwordRepeatHint')))
      } else if (value !== this.resetSetForm.password) {
        callback(new Error(this.$t('nav.passwordRepeatError')))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      visible: false,
      loginForm: {
        user: '',
        password: ''
      },
      registerForm: {
        name: '',
        surname: '',
        email: '',
        password: '',
        password2: '',
        phone: '',
        forAgent: false,
        forBiz: false,
        code: null
      },
      resetMessage: {
        type: 'info',
        text: ''
      },
      resetAskForm: {
        user: ''
      },
      resetSetForm: {
        user: '',
        hash: '',
        password: '',
        password2: ''
      },
      errMsgRegisterForm: '',
      rulesRegisterForm: {
        email: [
          {
            required: true,
            message: this.$t('nav.emailHint'),
            trigger: 'blur'
          },
          { type: 'email', message: this.$t('nav.emailValid'), trigger: 'blur' }
        ],
        name: [
          {
            required: true,
            message: this.$t('nav.nameHint'),
            trigger: 'blur'
          },
          { min: 2, message: this.$t('nav.nameHintMin'), trigger: 'blur' }
        ],
        surname: [
          {
            required: true,
            message: this.$t('nav.nameHint'),
            trigger: 'blur'
          },
          { min: 2, message: this.$t('nav.nameHintMin'), trigger: 'blur' }
        ],
        password: [
          {
            required: true,
            message: this.$t('nav.passwordHint'),
            trigger: 'blur'
          },
          { validator: validatePass, trigger: 'blur' }
        ],
        password2: [
          {
            required: true,
            message: this.$t('nav.passwordHint'),
            trigger: 'blur'
          },
          { validator: validatePass2, trigger: 'blur' }
        ],
        phone: [
          {
            required: true,
            message: this.$t('nav.phoneHint'),
            trigger: 'blur'
          },
          { min: 7, trigger: 'blur' }
        ]
      },
      rulesResetAskForm: {
        user: [
          {
            required: true,
            message: this.$t('nav.emailHint'),
            trigger: 'blur'
          },
          { type: 'email', message: this.$t('nav.emailValid'), trigger: 'blur' }
        ]
      },
      rulesResetSetForm: {
        password: [
          {
            required: true,
            message: this.$t('nav.passwordHint'),
            trigger: 'blur'
          },
          { validator: validatePassReset, trigger: 'blur' }
        ],
        password2: [
          {
            required: true,
            message: this.$t('nav.passwordHint'),
            trigger: 'blur'
          },
          { validator: validatePass2Reset, trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['getUser', 'getAuthHeader', 'getLoginDialog', 'getPromoAgentCode'])
  },
  created () {
    if (this.$route.query.user && this.$route.query.user.length > 0 && this.$route.query.hash && this.$route.query.hash.length > 0) {
      this.resetSetForm.user = this.$route.query.user
      this.resetSetForm.hash = this.$route.query.hash
      this.showResetSet()
    }
  },
  methods: {
    ...mapActions(['hideLogin', 'showLogin', 'showRegister', 'showResetAsk', 'showResetSet']),
    userLogin () {
      this.loginForm.user = this.loginForm.user.trim()
      this.loading = true
      this.$http
        .post('user/login/', {
          user: this.loginForm.user,
          password: this.loginForm.password
        })
        .then(
          (response) => {
            if (!response.body.error) {
              this.$store.commit('setJWT', response.body.jwt)
              Vue.http.headers.common.Authorization = this.getAuthHeader
              this.hideLogin()
            } else {
              console.log('err', response.body.error)
            }
          },
          (error) => {
            console.log(error.body ? error.body : 'userLogin error')
          }
        )
        .then(() => {
          this.loading = false
        })
    },
    userRegister () {
      this.registerForm.email = this.registerForm.email.trim()
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.registerForm.forAgent = this.getLoginDialog.type === 4
          this.registerForm.forBiz = this.getLoginDialog.type === 5
          this.errMsgRegisterForm = ''
          if (this.getPromoAgentCode && this.getPromoAgentCode.length === 8) {
            this.registerForm.code = this.getPromoAgentCode
          }
          this.$http
            .post('user/register/', this.registerForm)
            .then(
              (response) => {
                if (!response.body.error) {
                  this.$store.commit('setJWT', response.body.jwt)
                  Vue.http.headers.common.Authorization = this.getAuthHeader
                  this.hideLogin()
                  if (response.body.redirect && response.body.redirect.name && response.body.redirect.name.length > 0) {
                    this.$router.push(response.body.redirect)
                  }
                } else {
                  console.log('err', response.body.error)
                }
              },
              (error) => {
                if (error.body && error.body.error) {
                  this.errMsgRegisterForm = error.body.error
                  this.$refs.refEmail.focus()
                }
                console.log(error.body ? error.body : 'userRegister error')
              }
            )
            .then(() => {
              this.loading = false
            })
        } else {
          return false
        }
      })
    },
    userResetAsk () {
      this.resetAskForm.user = this.resetAskForm.user.trim()
      this.$refs.resetAskForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.$http
            .post('user/reset_ask/', this.resetAskForm)
            .then(
              (response) => {
                if (!response.body.error) {
                  this.resetMessage.text = this.$t('nav.passwordResetAskOK')
                  this.resetMessage.type = 'success'
                } else {
                  this.resetMessage.text = this.$t('nav.passwordResetAskKO')
                  this.resetMessage.type = 'error'
                  console.log('err', response.body.error)
                }
              },
              (error) => {
                this.loading = false
                if (error.body && error.body.error) {
                  this.resetMessage.text = this.$t('nav.passwordResetAskKO')
                  this.resetMessage.type = 'error'
                  this.$refs.refEmail.focus()
                }
                console.log(error.body ? error.body : 'userResetAsk error')
              }
            )
            .then(() => {
              this.loading = false
            })
        } else {
          return false
        }
      })
    },
    userResetSet () {
      this.$refs.resetSetForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.$http
            .post('user/reset_set/', this.resetSetForm)
            .then(
              (response) => {
                if (!response.body.error) {
                  this.resetMessage.text = this.$t('nav.passwordResetSetOK')
                  this.resetMessage.type = 'success'
                } else {
                  this.resetMessage.text = this.$t('nav.passwordResetSetKO')
                  this.resetMessage.type = 'error'
                  console.log('err', response.body.error)
                }
              },
              (error) => {
                this.loading = false
                if (error.body && error.body.error) {
                  this.resetMessage.text = this.$t('nav.passwordResetSetKO')
                  this.resetMessage.type = 'error'
                }
                console.log(error.body ? error.body : 'userResetSet error')
              }
            )
            .then(() => {
              this.loading = false
            })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less">
.ra-dialog-login {
  .el-dialog__body {
    padding-bottom: 20px;
  }
  .el-form--label-top .el-form-item__label {
    padding: 0;
  }
  &-type {
    &-0,
    &-1,
    &-2,
    &-3,
    &-4,
    &-5 {
      .el-dialog {
        max-width: 400px;
      }
    }
  }
}
</style>

<style lang="less" scoped>
.ra-dialog-login {
  &-content {
    padding: 0 10px 20px 10px;
  }
  &-link {
    text-align: right;
  }
}
</style>
