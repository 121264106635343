import Vue from 'vue'
import VueRouter from 'vue-router'

// Middleware
import VueRouteMiddleware from 'vue-route-middleware'
import Auth from './middleware/auth'
import { isAdmin, isBiz, isAgent } from './middleware/perms'

// Views
import Home from '../views/Home.vue'
// import Join from '../views/Join.vue'
// import Privacy from '../views/Privacy.vue'
// import Terms from '../views/Terms.vue'
// import Me from '../views/Me.vue'
// import Fav from '../views/Fav.vue'
// import UserOrders from '../views/UserOrders.vue'
// import Bizs from '../views/Bizs.vue'
// import Biz from '../views/Biz.vue'
// import Admin from '../views/Admin.vue'
// import Agent from '../views/Agent.vue'
import Restaurant from '../views/Restaurant.vue'

// Components
// import Loading from '../components/Loading'
// import BizDashboard from '../components/BizDashboard'
// import BizOrders from '../components/BizOrders'
// import BizBasic from '../components/BizBasic'
// import BizLanguages from '../components/BizLanguages'
// import BizDayTimes from '../components/BizDayTimes'
// import BizCuisine from '../components/BizCuisine'
// import BizAllergens from '../components/BizAllergens'
// import BizMenuCategories from '../components/BizMenuCategories'
// import BizMenuItems from '../components/BizMenuItems'
// import BizMenues from '../components/BizMenues'
// import BizMenuesCategories from '../components/BizMenuesCategories'
// import BizMenuesItems from '../components/BizMenuesItems'
// import BizImages from '../components/BizImages'
// import BizGallery from '../components/BizGallery'
// import BizQr from '../components/BizQr'
// import BizSubs from '../components/BizSubs'
// import AdminDashboard from '../components/AdminDashboard'
// import AdminLangAllergens from '../components/AdminLangAllergens.vue'
// import AdminLangCuisines from '../components/AdminLangCuisines.vue'
// import AdminAgents from '../components/AdminAgents.vue'
// import AdminUsers from '../components/AdminUsers.vue'
// import AgentContacts from '../components/AgentContacts.vue'
// import AgentDashboard from '../components/AgentDashboard.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'home', component: Home },
  {
    path: '/join/:id?',
    name: 'join',
    component: () => import('../views/Join.vue')
  },
  {
    path: '/license',
    name: 'license',
    component: () => import('../views/License.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../views/Privacy.vue')
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('../views/Terms.vue')
  },
  {
    path: '/myfavorites',
    name: 'myfavorites',
    component: () => import('../views/Fav.vue'),
    meta: { middleware: [Auth] }
  },
  {
    path: '/myorders',
    name: 'myorders',
    component: () => import('../views/UserOrders.vue'),
    meta: { middleware: [Auth] }
  },
  {
    path: '/me/:status?',
    name: 'me',
    component: () => import('../views/Me.vue'),
    meta: { middleware: [Auth] }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/Contact.vue'),
    meta: { middleware: [Auth] }
  },
  {
    path: '/agent',
    name: 'agent',
    component: () => import('../views/Agent.vue'),
    meta: { middleware: [Auth, isAgent] },
    children: [
      {
        path: 'dashboard',
        name: 'agent-dashboard',
        component: () => import('../components/AgentDashboard.vue')
      },
      {
        path: '/agents',
        name: 'agent-agents',
        component: () => import('../components/AgentAgents.vue')
      },
      {
        path: 'contacts/:contactID?',
        name: 'agent-contacts',
        component: () => import('../components/AgentContacts.vue')
      },
      {
        path: 'sales',
        name: 'agent-sales',
        component: () => import('../components/AgentSales.vue')
      },
      {
        path: 'codes',
        name: 'agent-codes',
        component: () => import('../components/AgentCodes.vue')
      },
      {
        path: 'documents',
        name: 'agent-documents',
        component: () => import('../components/AgentDocuments.vue')
      }
    ]
  },
  {
    path: '/bizs',
    name: 'bizs',
    component: () => import('../views/Bizs.vue'),
    meta: { middleware: [Auth] }
  },
  {
    path: '/biz/:id',
    name: 'biz',
    component: () => import('../views/Biz.vue'),
    meta: { middleware: [Auth, isBiz] },
    children: [
      {
        path: 'dashboard',
        name: 'biz-dashboard',
        component: () => import('../components/BizDashboard.vue')
      },
      {
        path: 'orders',
        name: 'biz-orders',
        component: () => import('../components/BizOrders.vue')
      },
      {
        path: 'config/basic',
        name: 'biz-config-basic',
        component: () => import('../components/BizBasic.vue')
      },
      {
        path: 'config/languages',
        name: 'biz-config-languages',
        component: () => import('../components/BizLanguages.vue')
      },
      {
        path: 'config/permissions',
        name: 'biz-config-permissions',
        component: () => import('../components/BizPermissions.vue')
      },
      {
        path: 'config/daytimes',
        name: 'biz-config-daytimes',
        component: () => import('../components/BizDayTimes.vue')
      },
      {
        path: 'config/cuisine',
        name: 'biz-config-cuisine',
        component: () => import('../components/BizCuisine.vue')
      },
      {
        path: 'config/allergens',
        name: 'biz-config-allergens',
        component: () => import('../components/BizAllergens.vue')
      },
      {
        path: 'config/qr',
        name: 'biz-config-qr',
        component: () => import('../components/BizQr.vue')
      },
      {
        path: 'menu/categories/:itemID?',
        name: 'biz-menu-categories',
        component: () => import('../components/BizMenuCategories.vue')
      },
      {
        path: 'menu/items/:itemID?',
        name: 'biz-menu-items',
        component: () => import('../components/BizMenuItems.vue')
      },
      {
        path: 'menus/list/:itemID?',
        name: 'biz-menus-list',
        component: () => import('../components/BizMenues.vue')
      },
      {
        path: 'menus/categories/:menuID?/:itemID?',
        name: 'biz-menus-categories',
        component: () => import('../components/BizMenuesCategories.vue')
      },
      {
        path: 'menus/items/:menuID?/:itemID?',
        name: 'biz-menus-items',
        component: () => import('../components/BizMenuesItems.vue')
      },
      {
        path: 'images',
        name: 'biz-images',
        component: () => import('../components/BizImages.vue')
      },
      {
        path: 'gallery',
        name: 'biz-gallery',
        component: () => import('../components/BizGallery.vue')
      },
      {
        path: 'subs/:rsID?/:status?',
        name: 'biz-subs',
        component: () => import('../components/BizSubs.vue')
      },
      {
        path: 'preconf/:foreignID?/:status?',
        name: 'biz-preconf',
        component: () => import('../components/BizPreconf.vue')
      },
      {
        path: 'maintenance/:rsID?/:status?',
        name: 'biz-maintenance',
        component: () => import('../components/BizMaintenance.vue')
      }
    ]
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/Admin.vue'),
    meta: { middleware: [Auth, isAdmin] },
    children: [
      {
        path: 'dashboard',
        name: 'admin-dashboard',
        component: () => import('../components/AdminDashboard.vue')
      },
      {
        path: 'restaurants',
        name: 'admin-restaurants',
        component: () => import('../components/AdminRestaurants.vue')
      },
      {
        path: 'users',
        name: 'admin-users',
        component: () => import('../components/AdminUsers.vue')
      },
      {
        path: 'agents',
        name: 'admin-agents',
        component: () => import('../components/AdminAgents.vue')
      },
      {
        path: 'codes',
        name: 'admin-codes',
        component: () => import('../components/AdminCodes.vue')
      },
      {
        path: 'lang/cuisines',
        name: 'admin-langcuisines',
        component: () => import('../components/AdminLangCuisines.vue')
      },
      {
        path: 'lang/allergens',
        name: 'admin-langallergens',
        component: () => import('../components/AdminLangAllergens.vue')
      }
    ]
  },
  {
    path: '/r/:id/:itemType?/:itemID?',
    name: 'restaurant',
    component: Restaurant
  },

  { path: '*', redirect: '/' }
]

function wait (duration) {
  return new Promise((resolve) => setTimeout(resolve, duration))
}

function isElementNearTop (el) {
  const rect = el.getBoundingClientRect()

  return (
    rect.top <= 100
  )
}

async function tryScrollToAnchor (hash, timeout = 1000, delay = 100, repeat = 0, first = true) {
  while (timeout > 0) {
    await wait(delay)
    const el = document.querySelector(hash)
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' })
      if (repeat > 0) {
        delay = first ? 470 : (delay < 200 ? 180 : delay)
        await wait(delay)
        if (!isElementNearTop(el)) {
          tryScrollToAnchor(hash, timeout, delay / 2, repeat - 1, false)
        }
      }
      break
    }
    timeout = timeout - delay
  }
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      tryScrollToAnchor(to.hash, 10000, 250, 5)
    } else if (savedPosition) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  }
})

router.beforeEach(VueRouteMiddleware())

export default router
