<template>
  <div
    v-if="card"
    :id="`result-r-card-${card.r_id}`"
    :ref="`result-r-card-${card.r_id}`"
    class="r-card"
    :class="{
      'r-card-popup': popUp,
      'r-card-horizontal': horizontal
    }"
    @click="handleCardClick(card.r_id)"
  >
    <div class="r-card-image" v-if="!((popUp || horizontal) && !card.premium_active)">
      <!-- Img -->
      <!-- <img v-bind:src="getImg" v-if="mediaURI && getImg" class="r-card-image" /> -->
      <el-image :src="getImg" fit="cover">
        <div slot="error" class="image-slot">
          <i class="el-icon-picture-outline"></i>
        </div>
      </el-image>
      <!-- Rate -->
      <!-- <div
        class="r-card-body-rate r-card-body-rate-vertical"
        :class="{ 'no-hover': popUp }"
        v-if="!horizontal"
      >
        <div>
          <el-rate
            v-model="ratingValue"
            show-score
            text-color="#ff9933"
            :score-template="`{value} (${card.votes_count})`"
            @click.native="handleRatingClick"
            @change="handleRatingChange"
          >
          </el-rate>
        </div>
        <div
          class="r-card-body-rate-fav r-card-body-rate-fav-vertical"
          @click="handleFavoriteClick"
        >
          <span @click="handleFavoriteChange">
            <i
              @click="favoriteChecked = !favoriteChecked"
              class="
                r-card-body-rate-fav-icon r-card-body-rate-fav-icon-vertical
                el-icon-s-flag
              "
              :class="{ 'r-card-body-rate-fav-icon-active': favoriteChecked }"
            ></i>
          </span>
        </div>
      </div> -->
    </div>
    <div class="r-card-body">
      <!-- Bookmark -->
      <div
        v-if="checkUser"
        class="r-card-body-fav"
        @click.stop="handleFavoriteChange"
        :class="{ 'r-card-body-fav-active': favoriteChecked }"
      ></div>
      <!-- Title -->
      <div
        class="r-card-body-info r-card-body-info-title"
        :title="card.r_name_fb"
      >
        <b>{{ card.r_name_fb }}</b>
      </div>
      <!-- Cuisines -->
      <div class="r-card-body-info r-card-body-info-cuisine">
        <template v-if="card.rcu_primary">
          {{ $t('general.cuisine') }}
          {{
            card.rcu_secondary
              ? `${card.rcu_primary}, ${card.rcu_secondary}`
              : card.rcu_primary
          }}
        </template>
      </div>
      <!-- <div class="r-card-body-info" :class="isOpenComment.class">
        <span>{{ isOpenComment.msg }}</span>
      </div> -->
      <!-- TakeAway -->
      <div class="r-card-body-info r-card-body-info-take_away">
        {{ $t('general.takeAway') }}
        <span
          v-if="!!card.r_takeaway"
          class="
            r-card-body-info-take_away-mark r-card-body-info-take_away-mark-yes
          "
        >
          <i class="el-icon-check" />
        </span>
        <span
          v-else
          class="
            r-card-body-info-take_away-mark r-card-body-info-take_away-mark-no
          "
        >
          <i class="el-icon-close" />
        </span>
      </div>

      <!-- Price Range -->
      <div
        class="r-card-body-info r-card-body-info-price"
        v-if="getPriceRange > 0"
      >
        {{ $t('general.price') }}
        <span
          :class="`r-card-body-info-price-range r-card-body-info-price-range${getPriceRange}`"
        />
      </div>

      <!-- Rate -->
      <!-- <div
        class="r-card-body-rate r-card-body-rate-horizontal"
        :class="{ 'no-hover': popUp }"
        v-if="horizontal"
      >
        <div>
          <el-rate
            v-model="ratingValue"
            show-score
            text-color="#ff9933"
            :score-template="`{value} (${card.votes_count})`"
            @click.native="handleRatingClick"
            @change="handleRatingChange"
          >
          </el-rate>
        </div>
        <div class="r-card-body-rate-fav" @click="handleFavoriteClick">
          <span @click="handleFavoriteChange">
            <i
              @click="favoriteChecked = !favoriteChecked"
              class="r-card-body-rate-fav-icon el-icon-s-flag"
              :class="{ 'r-card-body-rate-fav-icon-active': favoriteChecked }"
            ></i>
          </span>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'card',
  props: ['card', 'popUp', 'isMobil', 'horizontal', 'mediaURI', 'idCard'],
  data () {
    return {
      id: this.card.r_id,
      ratingValue: parseFloat(this.card.votes_avg.toFixed(1)),
      favoriteChecked: !!this.card.favorite
    }
  },
  beforeMount () {},
  computed: {
    ...mapGetters(['getMediaURI', 'getUser']),
    getImg () {
      if (this.popUp && !this.card.premium_active) {
        return ''
      }
      if (this.mediaURI) {
        return this.card.r_image_file && this.card.r_image_file.length
          ? `${this.mediaURI}sm/${this.card.r_image_file}`
          : ''
      }
      return this.card.r_image_file && this.card.r_image_file.length
        ? `${this.getMediaURI}sm/${this.card.r_image_file}`
        : ''
    },
    checkUser () {
      return this.getUser.id > 0
    },
    getPriceRange () {
      return parseInt(this.card.r_price_range)
    },
    isOpenComment () {
      let status = false
      if (this.card.is_open && this.card.is_open.open) status = true

      return {
        status,
        msg: status ? this.$t('general.open') : this.$t('general.closed'),
        class: status ? 'r-card-body-info-open' : 'r-card-body-info-close'
      }
    }
  },
  methods: {
    handleCardClick (id) {
      this.$emit('click', id)
    },
    handleRatingClick (e) {
      e.stopPropagation()
    },
    handleRatingChange () {
      this.$http
        .post('restaurant/vote/', {
          id: this.id,
          value: this.ratingValue
        })
        .then(
          (response) => {
            if (!response.body.error) {
              if (response.body.voted) {
                this.$notify({
                  title: this.$msg.success,
                  message: this.$msg.saved,
                  type: 'success'
                })
              }
            } else {
              console.log('err', response.body.error)
            }
          },
          (error) => {
            console.log(error.body ? error.body : 'vote save error')
          }
        )
    },
    handleFavoriteClick (e) {
      e.stopPropagation()
    },
    handleFavoriteChange () {
      if (!this.favoriteChecked) {
        this.$http
          .post('restaurant/favorite/', {
            id: this.id
          })
          .then(
            (response) => {
              if (!response.body.error) {
                if (response.body.favorized) {
                  this.$notify({
                    title: this.$msg.success,
                    message: this.$msg.saved,
                    type: 'success'
                  })
                  this.favoriteChecked = !this.favoriteChecked
                }
              } else {
                console.log('err', response.body.error)
              }
            },
            (error) => {
              console.log(error.body ? error.body : 'favorite save error')
            }
          )
      } else {
        this.$http.delete(`restaurant/favorite/?id=${this.id}`).then(
          (response) => {
            if (!response.body.error) {
              if (response.body.favorized) {
                this.$notify({
                  title: this.$msg.success,
                  message: this.$msg.saved,
                  type: 'success'
                })
                this.favoriteChecked = !this.favoriteChecked
              }
            } else {
              console.log('err', response.body.error)
            }
          },
          (error) => {
            console.log(error.body ? error.body : 'favorite save error')
          }
        )
      }
    }
  }
}
</script>

<style lang="less">
@import url('../assets/css/variables.less');
// @break-mobile: 488px;
.no-hover {
  pointer-events: none;
}
.r-card {
  width: 200px;
  min-width: 200px;
  max-width: 300px;
  flex-grow: 1;
  position: relative;
  cursor: pointer;
  margin: 0.5em;
  max-height: 320px;
  background-color: #fff;
  transition: all 0.25s;
  box-shadow: 1px 0 5px rgba(0, 0, 0, 0.05), -1px 0 5px rgba(0, 0, 0, 0.05),
    0 1px 5px rgba(0, 0, 0, 0.05), 0 -1px 5px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  @media (max-width: @break-mobile) {
    max-width: @break-mobile;
  }
  &.flex-grow {
    flex-grow: 1;
    margin: 0 !important;
    margin-bottom: 1em !important;
    width: 100% !important;
  }
  &:hover {
    box-shadow: 1px 0 2px #ff9933, -1px 0 2px #ff9933, 0 1px 2px #ff9933,
      0 -1px 2px #ff9933, 0 0 10px rgba(0, 0, 0, 0.4);
    border-color: #ff9933;
  }
  &-image {
    width: 100%;
    height: 180px;
    position: relative;
    border-radius: 4px 4px 0 0;
    overflow: hidden;
    .el-image {
      width: 100%;
      height: 100%;
      .image-slot {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        font-size: 30px;
        background: #f5f7fa;
        color: grey;
      }
    }
  }
  &-body {
    box-sizing: border-box;
    padding: 10px;
    &-fav {
      position: absolute;
      top: -1px;
      right: 10px;
      box-sizing: border-box;
      border: 10px solid @color-border-hover;
      border-top: 0;
      border-bottom: 8px solid transparent;
      height: 34px;
      width: 0;
      filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));
      transition: all 0.25s;
      &:before {
        content: '';
        top: 1px;
        right: -9px;
        position: absolute;
        width: 16px;
        height: 29px;
        border: 1px dashed rgba(255, 255, 255, 0.6);
        border-top: 0;
        border-bottom: 0;
        transition: all 0.25s;
        filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.25));
      }
      &-active {
        border-left-color: @color-primary;
        border-right-color: @color-primary;
      }
      &:hover {
        height: 38px;
        filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
        &:before {
          height: 33px;
        }
      }
    }
    // &-rate {
    //   position: absolute;
    //   font-weight: bold;
    //   &-vertical {
    //     color: white;
    //     padding: 8px 10px;
    //     background: rgba(0, 0, 0, 0.6);
    //     bottom: 0;
    //     left: 0;
    //     right: 0;
    //   }
    //   &-horizontal {
    //     display: flex;
    //     flex-direction: row;
    //     flex-wrap: nowrap;
    //     justify-content: space-between;
    //     width: 234px;
    //     bottom: 10px;
    //   }
    //   &-fav {
    //     cursor: pointer;
    //     &-vertical {
    //       position: absolute;
    //       top: 10px;
    //       right: 10px;
    //     }
    //     &-icon {
    //       &-vertical {
    //         color: white;
    //       }
    //       &-active {
    //         color: #ff9933;
    //       }
    //     }
    //   }
    // }
    &-info {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      &-title {
        text-transform: capitalize;
        margin: 5px 0 5px 0;
      }
      &-cuisine {
        font-size: 80%;
        height: 18px;
        line-height: 18px;
        margin-bottom: 5px;
        color: lighten(@color-font, 20%);
      }
      &-price {
        font-size: 80%;
        height: 18px;
        line-height: 18px;
        &-range {
          margin-top: 4px;
          float: right;
          width: 44px;
          height: 10px;
          box-sizing: border-box;
          position: relative;
          border: 1px solid @color-border;
          border-radius: 5px;
          &::before {
            content: '';
            position: absolute;
            left: 1px;
            top: 1px;
            width: 10px;
            height: 6px;
            background: #88dd22;
            border-radius: 3px;
          }
          &2 {
            &::before {
              width: 20px;
              background: #99dd22;
              background: linear-gradient(90deg, #88dd22 0%, #99dd22 100%);
            }
          }
          &3 {
            &::before {
              width: 30px;
              background: #aadd22;
              background: linear-gradient(90deg, #88dd22 0%, #aadd22 100%);
            }
          }
          &4 {
            &::before {
              width: 40px;
              background: #bbdd22;
              background: linear-gradient(90deg, #88dd22 0%, #bbdd22 100%);
            }
          }
        }
      }
      &-take_away {
        font-size: 80%;
        height: 18px;
        line-height: 18px;
        &-mark {
          float: right;
          i {
            font-weight: 900;
          }
          &-yes {
            color: @color-check-ok;
          }
          &-no {
            color: @color-check-no;
          }
        }
      }
      &-open {
        font-size: 80%;
      }
      &-close {
        font-size: 80%;
        color: rgb(245, 140, 140);
      }
    }
  }
  &-horizontal {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    max-width: unset;
    .r-card {
      &-image {
        width: 120px;
        height: 110px;
        display: inline-block;
        border-radius: 4px 0 0 4px;
      }
      &-body {
        flex: 1 1 auto;
      }
    }
  }
  &-popup {
    font-size: 1rem;
    width: 220px;
    margin: -15px -10px !important; // to remove default maplibre paddings
    // margin: 0 !important;
    .r-card {
      &-body {
        &-fav {
          display: none;
        }
      }
    }
  }
}
</style>
