<template>
  <div class="r-map">
    <div class="r-map" id="drawerMap" v-if="drawerMap"></div>
    <div class="r-map" id="map" @click="allowZoom" v-else></div>

    <div class="r-map-card-template">
      <div class="r-map-card-holder" ref="card">
        <card
          v-if="card"
          :horizontal="false"
          :popUp="true"
          :card="card"
          @click="handleCardClickPopUp(card)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import mapboxgl from 'maplibre-gl'
import 'maplibre-gl/dist/maplibre-gl.css'
import { mapGetters } from 'vuex'
// import Card from '@/components/Card.vue'
// import Vue from 'vue'
import Card from './Card.vue'

export default {
  props: ['cards', 'cardHover', 'visible', 'drawerMap'],
  components: { Card },

  data () {
    return {
      map: null,
      mapProps: {
        bounds: null,
        accessToken:
          'pk.eyJ1IjoibW9sa2VubnkiLCJhIjoiY2tyMGt6eDI5MXN1eTJxbzZrYzk0MGlrMSJ9.FJOVJQId7Uuh9jAiZVDSJA', // Change this for the one for restaurania
        mapStyle:
          'https://api.maptiler.com/maps/streets/style.json?key=DzY1UP9k0dDnbgFDnU6o',
        maxZoom: 19,
        minZoom: 5,
        notSendBounds: false
      },
      card: null,
      popup: null
    }
  },
  mounted () {
    this.setbounds()
    this.loadMap()
    this.popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      closeOnMove: true
    })
  },
  computed: {
    ...mapGetters(['getFilters']),
    geoJSON () {
      const result = {
        type: 'FeatureCollection',
        features: []
      }
      for (const card of this.cards) {
        const loc = card.r_location.split(',')
        if (loc.length === 2) {
          result.features.push({
            type: 'Feature',
            properties: {
              id: card.id,
              title: card.title,
              card: card
            },
            geometry: {
              type: 'Point',
              coordinates: [parseFloat(loc[0]), parseFloat(loc[1])]
            }
          })
        }
      }
      return result
    },
    getCurrentLocationCoordinates () {
      if (this.getFilters.location) {
        return [this.getFilters.location.lng, this.getFilters.location.lat]
      }
      return false
    }
  },
  watch: {
    visible (value) {
      if (value && this.map) {
        this.map.resize()
        this.setbounds()
        this.fitBounds()
      }
    },
    cards () {
      if (this.map && this.map.getSource('restaurants')) {
        this.map.getSource('restaurants').setData(this.geoJSON)
        if (!this.getFilters.mapBounds) {
          this.mapProps.notSendBounds = true
          this.setbounds()
          this.fitBounds()
          this.mapProps.notSendBounds = false
        }
      }
    },
    cardHover () {
      this.removePopUps()
      if (this.cardHover && this.cardHover.r_location) {
        const arrCoordinates = this.cardHover.r_location.split(',')
        const coordinates = [
          parseFloat(arrCoordinates[0]),
          parseFloat(arrCoordinates[1])
        ]
        // TODO: refine
        this.map.easeTo({
          center: coordinates,
          zoom: 16,
          duration: 1000
        })
        this.card = this.cardHover
        this.createPopUp(this.cardHover, coordinates)
      }
    }
  },

  methods: {
    loadMap () {
      this.map = new mapboxgl.Map({
        container: 'map',
        style: this.mapProps.mapStyle,
        accessToken: this.mapProps.accessToken,
        bounds: this.mapProps.bounds,
        fadeDuration: 0,
        maxZoom: this.mapProps.maxZoom,
        minZoom: this.mapProps.minZoom
      })

      this.map.on('sourcedata', this.onSourceDataLoaded)

      this.map.on('load', (_) => {
        this.loadMapData()
        this.createClusters()
        this.createMarkers()

        const nav = new mapboxgl.NavigationControl()
        this.map.addControl(nav)
        const geolocate = new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true
          },
          trackUserLocation: true
        })

        this.map.addControl(geolocate, 'top-right')

        // Disable Zoom
        this.map.scrollZoom.disable()
        this.map.scrollZoom.setWheelZoomRate(0.02)
        // Reload functions
        this.map.on('dragend', (e) => this.returnBounds(e))
        this.map.on('zoomend', (e) => this.returnBounds(e))
      })
    },
    loadMapData () {
      // Add data to map
      this.map.addSource('restaurants', {
        type: 'geojson',
        data: this.geoJSON,
        cluster: true,
        clusterMaxZoom: 14, // Max zoom to cluster points on
        clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
      })
    },
    createClusters () {
      // Clusters
      this.map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'restaurants',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': '#ff9933',
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40
          ]
        }
      })

      // Cluster Number
      this.map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'restaurants',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['Helvetica Neue', 'Helvetica', 'Arial'],
          'text-size': 14
        }
      })

      // Events
      const map = this.map
      this.map.on('click', 'clusters', (e) => {
        const features = map.queryRenderedFeatures(e.point, {
          layers: ['clusters']
        })
        const clusterId = features[0].properties.cluster_id
        map
          .getSource('restaurants')
          .getClusterExpansionZoom(clusterId, function (err, zoom) {
            if (err) return
            map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom
            })
          })
      })
      this.map.on('mouseenter', 'clusters', () => {
        map.getCanvas().style.cursor = 'pointer'
      })

      this.map.on('mouseleave', 'clusters', () => {
        map.getCanvas().style.cursor = ''
      })
    },
    createMarkers () {
      // Simple Marker
      this.map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'restaurants',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': '#ff9933',
          'circle-radius': 8,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff'
        }
      })

      if (this.getCurrentLocationCoordinates) {
        new mapboxgl.Marker({ scale: '0.5' })
          .setLngLat(this.getCurrentLocationCoordinates)
          .addTo(this.map)
      }

      // Events
      const map = this.map
      this.map.on('mouseenter', 'unclustered-point', (e) => {
        map.getCanvas().style.cursor = 'pointer'
        this.removePopUps()
        const coordinates = e.features[0].geometry.coordinates.slice()

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
        }
        const card = JSON.parse(e.features[0].properties.card)
        this.card = card
        this.createPopUp(card, coordinates)
        this.handleCardHoverPopUp(card)
      })
      this.map.on('click', 'unclustered-point', (e) => {
        map.getCanvas().style.cursor = 'pointer'
        this.removePopUps()
        const coordinates = e.features[0].geometry.coordinates.slice()

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
        }
        const card = JSON.parse(e.features[0].properties.card)
        this.card = card
        this.createPopUp(card, coordinates)
        this.handleCardHoverPopUp(card)
      })
      this.map.on('mouseleave', 'unclustered-point', () => {
        map.getCanvas().style.cursor = ''
      })
    },
    allowZoom () {
      if (this.map) this.map.scrollZoom.enable()
    },
    onSourceDataLoaded (event) {
      if (event.isSourceLoaded) {
        this.map.off('sourcedata', this.onSourceDataLoaded)
        this.setbounds()
        this.fitBounds()
      }
    },
    setbounds () {
      this.mapProps.bounds = null
      if (this.getFilters.useLocation) {
        this.currentLocation = [
          this.getFilters.location.lng,
          this.getFilters.location.lat
        ]
        this.mapProps.bounds = this.calculate_square_radius(
          this.getFilters.location.lat,
          this.getFilters.location.lng,
          this.getFilters.locationDistance
        )
      } else if (this.getFilters.useDestination) {
        if (
          this.getFilters.destinationLocation &&
          (this.geoJSON.features.length > 0 ||
            !this.getFilters.destinationBounds)
        ) {
          this.mapProps.bounds = this.calculate_square_radius(
            this.getFilters.destinationLocation.lat,
            this.getFilters.destinationLocation.lng,
            1000
          )
        } else if (this.getFilters.destinationBounds) {
          this.mapProps.bounds = [
            [
              this.getFilters.destinationBounds.sw.lng,
              this.getFilters.destinationBounds.sw.lat
            ],
            [
              this.getFilters.destinationBounds.ne.lng,
              this.getFilters.destinationBounds.ne.lat
            ]
          ]
        }
        this.expandBounds()
      }
    },
    expandBounds () {
      if (this.geoJSON.features.length > 0) {
        const result = new mapboxgl.LngLatBounds(
          this.mapProps.bounds[0],
          this.mapProps.bounds[1]
        )
        for (const feature of this.geoJSON.features) {
          result.extend(feature.geometry.coordinates)
        }
        this.mapProps.bounds = [
          [result._sw.lng, result._sw.lat],
          [result._ne.lng, result._ne.lat]
        ]
      }
    },
    fitBounds () {
      if (this.mapProps.bounds != null) {
        this.map.fitBounds(this.mapProps.bounds)
      }
    },
    calculate_square_radius (lat, lng, radius) {
      const earthRadius = 6371.01 // earth radius in km
      const radiusAux = radius * 0.001 // convert to km
      const latMin = lat - this.radians_to_degrees(radiusAux / earthRadius)
      const latMax = lat + this.radians_to_degrees(radiusAux / earthRadius)
      const lngMin =
        lng -
        this.radians_to_degrees(
          radiusAux / earthRadius / Math.cos(this.degrees_to_radians(lat))
        )
      const lngMax =
        lng +
        this.radians_to_degrees(
          radiusAux / earthRadius / Math.cos(this.degrees_to_radians(lat))
        )

      return [
        [lngMax, latMax],
        [lngMin, latMin]
      ]
    },
    radians_to_degrees (radians) {
      const pi = Math.PI
      return radians * (180 / pi)
    },
    degrees_to_radians (degrees) {
      const pi = Math.PI
      return degrees * (pi / 180)
    },
    returnBounds (e) {
      if (
        e.originalEvent &&
        (e.originalEvent instanceof MouseEvent ||
          e.originalEvent instanceof TouchEvent ||
          e.originalEvent instanceof WheelEvent)
      ) {
        const bounds = this.map.getBounds()
        if (!this.checkIfPopUpIsOpen() && !this.notSendBounds) {
          this.$store.commit('setMapBounds', {
            ne: bounds._ne,
            sw: bounds._sw
          })
          this.$emit('dragend')
          // this.$store.commit('setFilterStatus', true)
        }
      }
    },
    createPopUp (card, coordinates) {
      new mapboxgl.Popup({ closeButton: false })
        .setLngLat(coordinates)
        .setDOMContent(this.$refs.card)
        .addTo(this.map)
    },
    removePopUps () {
      const popup = document.getElementsByClassName('mapboxgl-popup')
      if (popup.length) {
        popup[0].remove()
      }
    },
    handleCardClickPopUp (card) {
      this.$emit('cardClick', card.r_id)
    },
    handleCardHoverPopUp (card) {
      this.$emit('cardHover', card.r_id)
    },
    checkIfPopUpIsOpen () {
      const popup = document.getElementsByClassName('mapboxgl-popup')
      if (popup.length) {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="less">
// mapbox maplibre
button.mapboxgl-ctrl-attrib-button {
  display: none !important;
}
.r-map {
  height: 100%;
  width: 100%;
  background: lightgrey;
  &-card {
    &-template {
      display: none;
    }
    // &-holder {
    //   min-width: 200px;
    //   height: 260px;
    // }
  }
}
.mapboxgl-popup-content,
.mapboxgl-ctrl-icon {
  padding: 0px !important;
}
</style>
