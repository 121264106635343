<template>
  <div class="r-map" id="map" @click="allowZoom"></div>
</template>

<script>
import mapboxgl from 'maplibre-gl'
import 'maplibre-gl/dist/maplibre-gl.css'
// import { mapGetters } from 'vuex'

export default {
  props: ['lat', 'lng'],
  data () {
    return {
      map: null,
      mapProps: {
        bounds: null,
        accessToken:
          'pk.eyJ1IjoibW9sa2VubnkiLCJhIjoiY2tyMGt6eDI5MXN1eTJxbzZrYzk0MGlrMSJ9.FJOVJQId7Uuh9jAiZVDSJA', // Change this for the one for restaurania
        mapStyle:
          'https://api.maptiler.com/maps/streets/style.json?key=DzY1UP9k0dDnbgFDnU6o',
        maxZoom: 19,
        minZoom: 5
      }
    }
  },
  mounted () {
    this.loadMap()
  },
  methods: {
    loadMap () {
      this.map = new mapboxgl.Map({
        container: 'map',
        style: this.mapProps.mapStyle,
        accessToken: this.mapProps.accessToken,
        bounds: this.calculate_square_radius(this.lat, this.lng, 1000),
        fadeDuration: 0,
        maxZoom: this.mapProps.maxZoom,
        minZoom: this.mapProps.minZoom
      })

      this.map.on('load', _ => {
        new mapboxgl.Marker({ scale: '0.5' })
          .setLngLat([this.lng, this.lat])
          .addTo(this.map)
        const nav = new mapboxgl.NavigationControl()
        this.map.addControl(nav)
        const geolocate = new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true
          },
          trackUserLocation: true
        })

        this.map.addControl(geolocate, 'top-right')

        // Disable Zoom
        this.map.scrollZoom.disable()
        this.map.scrollZoom.setWheelZoomRate(0.02)
      })
    },
    allowZoom () {
      if (this.map) this.map.scrollZoom.enable()
    },
    calculate_square_radius (lat, lng, radius) {
      const earthRadius = 6371.01 // earth radius in km
      const radiusAux = radius * 0.001 // convert to km
      const latMin = lat - this.radians_to_degrees(radiusAux / earthRadius)
      const latMax = lat + this.radians_to_degrees(radiusAux / earthRadius)
      const lngMin =
        lng -
        this.radians_to_degrees(
          radiusAux / earthRadius / Math.cos(this.degrees_to_radians(lat))
        )
      const lngMax =
        lng +
        this.radians_to_degrees(
          radiusAux / earthRadius / Math.cos(this.degrees_to_radians(lat))
        )

      return [
        [lngMax, latMax],
        [lngMin, latMin]
      ]
    },
    radians_to_degrees (radians) {
      const pi = Math.PI
      return radians * (180 / pi)
    },
    degrees_to_radians (degrees) {
      const pi = Math.PI
      return degrees * (pi / 180)
    }
  }
}
</script>

<style lang="less">
// mapbox maplibre
button.mapboxgl-ctrl-attrib-button {
  display: none !important;
}
.r-map {
  height: 100%;
  width: 100%;
  background: lightgrey;
}
.mapboxgl-popup-content,
.mapboxgl-ctrl-icon {
  padding: 0px !important;
}
</style>
