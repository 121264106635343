import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueResource from 'vue-resource'
import device from 'vue-device-detector-js'
import moment from 'moment'
import deepCopy from 'clone-deep'
import VueI18n from 'vue-i18n'

// import './plugins/element.js'
import Element from 'element-ui'
import './plugins/eui_theme/theme/index.css'

import langES from './i18n/es.json'
import langEN from './i18n/en.json'
import langFR from './i18n/fr.json'
import langIT from './i18n/it.json'
// import langDE from './i18n/de.json'
import langNL from './i18n/nl.json'
import langRU from './i18n/ru.json'

Vue.prototype.shareURL =
  process.env.NODE_ENV === 'production'
    ? 'https://service.restaurania.com/share/'
    : 'http://garagu.localhost/restaurania/share/'

Vue.prototype.printURL =
  process.env.NODE_ENV === 'production'
    ? 'https://service.restaurania.com/print/'
    : 'http://garagu.localhost/restaurania/print/'

Vue.prototype.qrURL =
  process.env.NODE_ENV === 'production'
    ? 'https://service.restaurania.com/qr/'
    : 'http://garagu.localhost/restaurania/qr/'

Vue.prototype.payURL =
  // process.env.NODE_ENV === 'production'
  //   ? 'https://service.restaurania.com/sync/redsys/'
  //   : 'http://garagu.localhost/restaurania/sync/redsys/'
  process.env.NODE_ENV === 'production'
    ? 'https://service.restaurania.com/sync/stripe/'
    : 'http://garagu.localhost/restaurania/sync/stripe/'

Vue.prototype.invoiceURL =
  process.env.NODE_ENV === 'production'
    ? 'https://billing.stripe.com/p/login/14k4k9e5f7IH4XCfYY'
    : 'https://billing.stripe.com/p/login/test_7sI4gV5PQfAS7tu4gg'

Vue.prototype.moment = moment
Vue.prototype.deepCopy = deepCopy
Vue.prototype.appLanguages = ['es', 'en', 'fr', 'it', 'nl', 'ru']
Vue.config.productionTip = false
Vue.use(VueResource)
Vue.use(device)
Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en',
  messages: {
    es: langES,
    en: langEN,
    fr: langFR,
    it: langIT,
    // de: langDE,
    nl: langNL,
    ru: langRU
  } // set locale messages
})

Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value)
})

// const loadedLanguages = []

// function setI18nLanguage (lang) {
//   i18n.locale = lang
//   // axios.defaults.headers.common['Accept-Language'] = lang
//   document.querySelector('html').setAttribute('lang', lang)
//   return lang
// }

// export function loadLanguageAsync (lang) {
//   // If the same language
//   if (i18n.locale === lang) {
//     return Promise.resolve(setI18nLanguage(lang))
//   }

//   // If the language was already loaded
//   if (loadedLanguages.includes(lang)) {
//     return Promise.resolve(setI18nLanguage(lang))
//   }

//   // If the language hasn't been loaded yet
//   const fileName = `@/i18n/${lang}.json`
//   return import(fileName).then(messages => {
//     loadedLanguages.push(lang)
//     // i18n.setLocaleMessage(lang, messages.default)
//     return setI18nLanguage(lang)
//   })
// }

// loadLanguageAsync('es')

// Vue.prototype.$t = str => {
//   return str
// }

Vue.prototype.$msg = {
  success: 'Éxito',
  saved: 'Datos guardados'
}

Vue.prototype.getTextLocale = (handle) => {
  if (handle && handle.fb) {
    if (
      handle.values &&
      handle.values[i18n.locale] &&
      handle.values[i18n.locale].trim() !== ''
    ) {
      return handle.values[i18n.locale].trim()
    } else if (
      handle.values &&
      handle.values[i18n.fallbackLocale] &&
      handle.values[i18n.fallbackLocale].trim() !== ''
    ) {
      return handle.values[i18n.fallbackLocale].trim()
    } else if (handle.fb !== '') {
      return handle.fb
    }
  }
  return ''
}

// Vue.http.options.root = 'http://garagu.localhost/restaurania/api/'
Vue.http.options.root =
  process.env.NODE_ENV === 'production'
    ? 'https://api.restaurania.com/'
    : 'http://garagu.localhost/restaurania/api/'

// call refreshJWT and wait to resolve before loading all the stuff...
// need this to have the permisions in routing checks
store.dispatch('refreshJWT').then(() => {
  new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App)
  }).$mount('#app')
})
