import store from '../../store'

export default (to, from, next) => {
  const token = store.getters.getJWT || false

  if (!token) {
    console.log('Not logged in')
    next({
      name: 'home'
    })
    return false
  }
}
