<template>
  <div
    class="r-input-search"
    :class="{ 'r-input-search-expandable': expandable }"
  >
    <div
      class="r-input-search-selected"
      v-if="
        getFilters.mapBounds ||
        getFilters.useLocation ||
        getFilters.useDestination
      "
    >
      <div
        class="r-input-search-selected-item"
        @click.stop="searchSelectedClean"
      >
        <template v-if="getFilters.mapBounds">
          <span
            class="
              r-input-search-selected-item-icon
              el-icon-discover
              r-input-search-autocomplete-item-icon-type-lo
            "
            @click.stop
          />
          <span class="r-input-search-selected-item-text">
            {{ $t('general.viewMap') }}
          </span>
        </template>
        <template v-else-if="getFilters.useLocation">
          <span
            class="
              r-input-search-selected-item-icon
              el-icon-location
              r-input-search-autocomplete-item-icon-type-lo
            "
            @click.stop
          />
          <span class="r-input-search-selected-item-text">
            {{ $t('general.myLocation') }}
          </span>
        </template>
        <template v-else-if="getFilters.useDestination">
          <span
            class="
              r-input-search-selected-item-icon
              el-icon-location-outline
              r-input-search-autocomplete-item-icon-type-d
            "
            @click.stop
          />
          <span class="r-input-search-selected-item-text">
            {{ getFilters.destinationText }}
          </span>
        </template>
        <span
          class="r-input-search-selected-item-close el-icon-close"
          @click.stop="searchSelectedClean"
        />
      </div>
    </div>
    <div class="r-input-search-autocomplete">
      <el-autocomplete
        class="r-input-search-autocomplete-input"
        :placeholder="
          hasGeoReference
            ? `${$t('biz.searchRestaurantCuisineDish')}`
            : `${$t('biz.searchCityRestaurant')}`
        "
        v-model="searchString"
        :fetch-suggestions="
          hasGeoReference ? querySearchAsync : querySearchNotGeoAsync
        "
        :select-when-unmatched="true"
        :highlight-first-item="true"
        @select="handleSelect"
        @clear="handleClear"
        :disabled="searchDisabled"
        clearable
        :maxlength="255"
        ref="rinputsearchautocomplete"
      >
        <i class="el-icon-search el-input__icon" slot="prefix"> </i>
        <template slot-scope="{ item }">
          <div class="r-input-search-autocomplete-item">
            <span class="r-input-search-autocomplete-item-icon">
              <i
                v-if="item.type === 'lo'"
                class="
                  el-icon-location
                  r-input-search-autocomplete-item-icon-type-lo
                "
              />
              <i v-else-if="item.type === 'x'" class="el-icon-close" />
              <i
                v-else-if="item.type === 'r'"
                class="
                  el-icon-knife-fork
                  r-input-search-autocomplete-item-icon-type-r
                "
              />
              <i
                v-else-if="item.type === 'd'"
                class="
                  el-icon-location-outline
                  r-input-search-autocomplete-item-icon-type-d
                "
              />
              <i
                v-else-if="item.type === 'p'"
                class="
                  el-icon-place
                  r-input-search-autocomplete-item-icon-type-p
                "
              />
              <i
                v-else-if="item.type === 'a'"
                class="
                  el-icon-location-information
                  r-input-search-autocomplete-item-icon-type-a
                "
              />
              <i
                v-else-if="item.type === 'c'"
                class="
                  el-icon-food
                  r-input-search-autocomplete-item-icon-type-c
                "
              />
              <i
                v-else
                :class="`el-icon-more r-input-search-autocomplete-item-icon-type-${item.type}`"
              />
            </span>
            <template v-if="item.type === 'ra'">
              {{ $t('biz.searchShowRestaurants') }}
              <strong>{{ item.count }}</strong>
            </template>
            <template v-else>
              {{ item.title }}
            </template>
          </div>
        </template>
      </el-autocomplete>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  props: {
    expandable: { type: Boolean, default: false }
  },
  data () {
    return {
      searchString: '',
      searchDisabled: false,
      locationDistance: 3000
    }
  },
  computed: {
    ...mapGetters(['getFilters']),
    hasGeoReference () {
      if (!this.getFilters.useLocation && !this.getFilters.useDestination) {
        return false
      }
      return true
    }
  },
  async beforeMount () {
    this.searchString = ''
  },
  methods: {
    ...mapActions(['getPosition']),
    querySearchNotGeoAsync (queryString, cb) {
      let result = []

      if (!queryString || queryString.length === 0) {
        result.push({
          id: null,
          location: null,
          title: this.$t('biz.searchWithYourLocation'),
          type: 'lo'
        })
      }

      if (queryString && queryString.length >= 3) {
        this.$store.commit('setFilterQuery', queryString)
        this.$http
          .post('info/search_not_georeference/', { filters: this.getFilters })
          .then(
            (response) => {
              // console.log(response)
              if (!response.body.error) {
                if (response.body.length) {
                  result = [...result, ...response.body]
                }
              } else {
                console.log('err', response.body.error)
              }
              cb(result)
            },
            (error) => {
              console.log(error.body ? error.body : 'search error')
              cb(result)
            }
          )
      } else {
        cb(result)
      }
    },
    querySearchAsync (queryString, cb) {
      let result = []

      if (!queryString || queryString.length === 0) {
        result.push({
          id: null,
          location: null,
          title: this.$t('general.locationChange'),
          type: 'x'
        })
        cb(result)
        return
      }

      if (queryString && queryString.length >= 3) {
        this.$store.commit('setFilterQuery', queryString)
        this.$http.post('info/search/', { filters: this.getFilters }).then(
          (response) => {
            if (!response.body.error) {
              if (response.body.length) {
                result = [...result, ...response.body]
              } else {
                this.querySearchNotGeoAsync(queryString, cb)
              }
            } else {
              console.log('err', response.body.error)
            }
            cb(result)
          },
          (error) => {
            console.log(error.body ? error.body : 'search error')
            cb(result)
          }
        )
      } else {
        cb(result)
      }
    },
    handleSelect (value) {
      this.searchString = ''

      switch (value.type) {
        case 'lo':
          this.selectCurrentLocation()
          break
        case 'x':
          this.searchSelectedClean(null, true)
          break
        case 'd':
          this.selectDestination(value)
          break
        case 'p':
          this.selectDestination(value)
          break
        case 'r':
          this.selectRestaurant(value.id)
          break
        case 'ra':
          this.selectText(value)
          break
        case 'c':
          this.selectCuisine(value)
          break
        case 'a':
          this.selectDestination(value)
          break
        default:
          break
      }

      if (value.type !== 'x') {
        this.$refs.rinputsearchautocomplete.$refs.input.blur()
      }

      this.$store.commit('setFilterStatus', true)
      this.$emit('selected', true)
      this.sendNewVisit()
    },
    selectRestaurant (id) {
      this.$router
        .push({
          name: 'restaurant',
          params: { id: `${id}` }
        })
        .catch(() => {})
    },
    selectCurrentLocation () {
      this.$store.commit('setLimitToLocation', true)
      this.$store.commit('setUseDestination', false)
      this.$store.commit('setDestinationBounds', {})
      this.$store.commit('setDestinationLocation', {})
      this.$store.commit('setMapBounds', null)
      this.$store.commit('setUseLocation', true)
      this.$store.commit('setDestinationID', 0)
    },
    selectDestination (value) {
      this.$store.commit('setFilterQuery', '')
      this.$store.commit('setDestinationText', value.title)

      if (value.id) {
        this.$store.commit('setDestinationID', value.id)
      }

      if (value.bounds) {
        this.$store.commit('setDestinationBounds', value.bounds)
        this.$store.commit('setDestinationLocation', value.location)
      } else {
        this.$store.commit('setDestinationBounds', {})
        this.$store.commit('setDestinationLocation', value.location)
      }

      this.$store.commit('setUseLocation', false)
      this.$store.commit('setUseDestination', true)
      this.$store.commit('setMapBounds', null)
    },
    selectText (value) {
      this.$store.commit('setFilterText', value.title)
      this.$store.commit('setFilterQuery', '')
      this.searchString = ''
    },
    selectCuisine (value) {
      this.$store.commit('setFilterCuisine', {
        id: value.id,
        name: value.title,
        seleted: true
      })
      this.$store.commit('setFilterQuery', '')
      this.searchString = ''
    },
    handleClear () {
      this.searchDisabled = true
      // this.$store.commit('clearFilters')
      setTimeout(() => {
        this.searchDisabled = false
      }, 10)
    },
    searchSelectedClean (e, all = false) {
      if (this.getFilters.mapBounds) {
        this.cleanFilters('mapa', false, !all)
        if (!all) {
          return
        }
      }
      if (this.getFilters.useLocation) {
        this.cleanFilters('location')
        this.$refs.rinputsearchautocomplete.focus()
        // this.$store.commit('setVisibleHome', true)
      } else if (this.getFilters.useDestination) {
        this.cleanFilters('destination')
        this.$refs.rinputsearchautocomplete.focus()
        // this.$store.commit('setVisibleHome', true)
      }
    },
    cleanFilters (value, aux = false, search = true) {
      switch (value) {
        case 'mapa':
          this.$store.commit('setMapBounds', null)
          break

        case 'location':
          this.$store.commit('setUseLocation', false)
          this.$store.commit('setLimitToLocation', false)
          break

        case 'destination':
          this.$store.commit('setUseDestination', false)
          this.$store.commit('setDestinationBounds', null)
          this.$store.commit('setDestinationLocation', null)
          break

        case 'text':
          this.$store.commit('setFilterText', '')
          break

        case 'cuisine': {
          this.$store.commit('removeFilterCuisine', aux)
          break
        }

        case 'takeaway': {
          this.$store.commit('setFilterTakeAway', false)
          break
        }

        case 'is-open': {
          this.$store.commit('setFilterIsOpen', false)
          break
        }

        case 'price-range': {
          this.$store.commit('setFilterPriceRange', null)
          break
        }

        case 'all': {
          this.$store.commit('removeFilterCuisine', false)
          this.$store.commit('setFilterTakeAway', false)
          this.$store.commit('setFilterIsOpen', false)
          this.$store.commit('setFilterPriceRange', null)
          break
        }

        default:
          break
      }
      if (search) {
        this.$store.commit('setFilterStatus', true)
      }
    },
    sendNewVisit () {
      this.$http
        .post('stats/new_visit', {
          type: 'search',
          device: this.$device.machine.type
        })
        .then(
          (response) => {},
          (error) => {
            console.log(error.body ? error.body : 'stat error')
          }
        )
    }
  }
}
</script>

<style lang="less">
@import url('../assets/css/variables.less');
body .home-search-container-inputs .r-input-search {
  max-width: 100%;
}
.r-input-search {
  &-autocomplete {
    &-input {
      .el-input__inner {
        border: none;
      }
    }
  }
}
</style>
<style lang="less" scoped>
@import url('../assets/css/variables.less');
.r-input-search {
  border-radius: @border-radius;
  background: white;
  // border: 1px solid @color-border;
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
  align-content: center;
  max-width: e('calc(100vw - 155px)');
  overflow: hidden;
  // TODO: Not working as expected ...
  // &-expandable {
  //   @media (max-width: @break-mobile) {
  //     &:focus-within {
  //       position: absolute;
  //       left: 10px;
  //       right: 10px;
  //       top: 12px;
  //       filter: drop-shadow(1px 1px 1px black);
  //       max-width: unset;
  //       width: e('calc(100vw - 20px)');
  //       z-index: 1;
  //     }
  //   }
  // }
  &-selected {
    box-sizing: border-box;
    margin: 4px 0;
    display: flex;
    &:hover {
      max-width: e('calc(100vw - 175px)');
    }
    &-item {
      display: flex;
      align-items: center;
      white-space: nowrap;
      height: 100%;
      margin-left: 4px;
      background: @color-fill;
      border-radius: @border-radius;
      border: 1px solid @color-border;
      box-sizing: border-box;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      max-width: e('calc((100vw - 195px) / 3)');
      min-width: 64px;
      @media (max-width: @break-mobile) {
        max-width: unset;
        min-width: unset;
      }
      &:hover {
        max-width: e('calc(100vw - 195px)');
        background: #fff5eb;
        border-color: @color-primary;
        .r-input-search-selected-item {
          &-icon,
          &-text {
            opacity: 0.65;
          }
          &-text {
            @media (max-width: @break-mobile) {
              width: auto;
              padding: 0 5px;
            }
          }
          &-close {
            color: @color-primary;
          }
        }
      }
      &-icon,
      &-text,
      &-close {
        transition: all 0.2s ease-in-out;
      }
      &-icon {
        margin-left: 5px;
        font-size: 22px;
      }
      &-text {
        padding: 0 5px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 14px;
        @media (max-width: @break-mobile) {
          width: 0;
          padding: 0;
        }
      }
      &-close {
        margin-right: 5px;
        font-size: 22px;
      }
    }
  }

  &-autocomplete {
    flex-grow: 1;
    &-input {
      width: 100%;
      .el-input__inner {
        border: none;
      }
    }
    &-item {
      position: relative;
      padding-left: 30px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      &-icon {
        position: absolute;
        left: 0;
        top: 0;
        &-type {
          &-lo,
          &-r,
          &-ra {
            color: #ff9933;
          }
          &-d {
            color: #3399ff;
          }
          &-a {
            color: #3399ff;
          }
          &-p {
            color: #9933ff;
          }
          &-c {
            color: #ff3399;
          }
        }
      }
    }
  }
}
.r-input-search-autocomplete-item-icon {
  i {
    font-size: 22px;
    line-height: 34px;
  }
}
</style>
